import { PrimarySelect } from "components/inputs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useAuth } from "store/auth";
import { useGetGroupsQuery } from "store/group";
import { useGetInterventionsQuery } from "store/intervention";
import { useProject } from "store/projects";
import { communities, locals, states } from "utilities";

export interface FilterSystemProps {
  query?: (value?: string) => void;
  state?: (value?: string) => void;
  lga?: (value?: string) => void;
  community?: (value?: string) => void;
  intervention?: (value?: Array<number | string>) => void;
}

export const FilterSystem: React.FC<FilterSystemProps>= ({
  query = () => {},
  state = () => {}, 
  lga = () => {}, 
  intervention = () => {}, 
  community = () => {},
}) => {
  const projectId: number = useProject().project.id;
  const { data: interventions } = useGetInterventionsQuery({ project_id: projectId });
  const interventionNames = interventions?.data.data.map((data: { name: any; id: any; }) => {
    return { text: `${data.name}`, props: { value: data.id  }}
  })
  const { isLoading } = useGetGroupsQuery({  query: "", project_id: projectId });
  const initRequest = () => { const payload: any = { ...values } };

  const { values, handleChange } = useFormik({
      initialValues: { 
        lga: "", 
        state: "", 
        intervention: "", 
        community: "",
        query: "" 
      }, onSubmit: async () => initRequest(),
  });

  const { user } = useAuth();
  const userRole = user?.account_type ?? ""
  const[userState, setUserState] = useState("")
  const[userLga, setUserLga] = useState("")

  useEffect(() => {
    if (values.query) query(values.query);
    if (values.state) state(values.state);
    if (values.community) community(values.community);
    if (values.lga) lga(values.lga);
    if (values.intervention) intervention([Number(values.intervention)]);

    const except = "community_facilitator state_coordinator"
    if (except.includes(userRole)) setUserState(user?.user_info?.state ?? "_");
    if ("community_facilitator".includes(userRole)) setUserLga(user?.user_info?.lga ?? "_");

    if (userState) state(userState);
    if (userLga) lga(userLga);

  }, [values, userState, userLga]);

    return (
      <div className="col-xl-12">
        <div className="row">
          <div className="col-md-3 col-auto mb-4">
            <PrimarySelect 
              name="state"
              placeholder="Select State"
              options={ states(userState) }
              onChange={handleChange}
              size={"lg"}
              style={{ backgroundColor: "#fff", border: "none", borderRadius: 0 }}
            />
          </div>
          
          <div className="col-md-3 col-auto mb-4">
            <PrimarySelect 
              name="lga"
              placeholder="Select Local Gov"
              options={ locals(values.state, userLga) }
              onChange={handleChange}
              size={"lg"}
              isDisabled={isLoading}
              style={{ backgroundColor: "#fff", border: "none", borderRadius: 0 }}
            />
          </div>

          <div className="col-md-3 col-auto mb-4">
            <PrimarySelect
              name="community"
              placeholder="Select Community"
              options={ communities(values.state, values.lga) }
              onChange={handleChange}
              size={"lg"}
              isDisabled={isLoading}
              style={{ backgroundColor: "#fff", borderRadius: 0, border: 0 }}
            />
          </div>

            <div className="col-md-3 col-auto mb-4">
              <PrimarySelect
                name="intervention"
                placeholder="Select Intervention"
                options={interventionNames}
                onChange={handleChange}
                size={"lg"}
                isDisabled={isLoading}
                style={{
                  backgroundColor: "#ffff",
                  borderRadius: 0,
                  border: 0,
                }}
              />
            </div>
            </div>
        
      </div>
    ); 
};


