import { Farmer } from 'store/farmers';
import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { GroupFarmers } from '@store/group';

export const useAllGroupHouseholdColumn = () => {
    return useMemo<MRT_ColumnDef<GroupFarmers>[]>(
      () => [
        {
          header: "First Name",
          accessorFn: (row) => `${row.farmer.first_name}`,
          Cell: ({ row }) => (
            <Link
              to={"/farmers/view/" + (row.original as GroupFarmers).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(row.original.farmer.first_name)}
            </Link>
          ),
        },
        {
          header: "Last Name",
          accessorFn: (row) => `${row.farmer.last_name}`,
          Cell: ({ row }) => (
            <Link
              to={"/farmers/view/" + (row.original.farmer as Farmer).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(row.original.farmer.last_name)}
            </Link>
          ),
        },
        {
          header: "Gender",
          accessorFn: (row) => `${row.farmer.farmer_gender}`,
        },
        {
          header: "Address",
          accessorFn: (row) => `${row.farmer.farmer_address}`,
        },
        {
          header: "Phone Number",
          accessorFn: (row) => `${row.farmer.farmer_phone}`,
        },
        {
          header: "Date Added",
          accessorFn: (row) => `${moment(row.farmer.created_at).format('MMMM Do, YYYY')}`,
        },
      ],
      []
    );
};