import { useToast } from "@chakra-ui/react";
import { PrimaryTextarea, PrimaryInput } from "components";
import { useNavigate } from "react-router-dom";
import { ContentBodyContainer } from "../../home";
import { useLocation } from "react-router-dom";
import { useProject } from "store/projects";
import { useEventQuery } from "store/event";


export const ViewEvent = () => {
  const navigate = useNavigate();
  const projectId: number = useProject().project?.id;
  const pathArray: string[] = useLocation().pathname.trim().split("/")
  const eventId = Number(pathArray[pathArray.length - 1])
  const { data, isLoading, refetch } = useEventQuery({ project_id: projectId, event_id: eventId });

  return (
    <ContentBodyContainer
      title="View Schedule"
      routesRule={"view schedule"}
    >
        <div className="col-md-12 col-sm-12">
          <div className="card custom-card">
            <div className="px-3 pt-3 align-items-center d-flex border-bottom">
              {/* {leftCardHeaderComponent} */}
              <div className="mb-0 flex-grow-1 ">
                <p
                  className="fs-5 fw-bold"
                  style={{
                    color: "#2A4153",
                  }}
                >
                  Story - { data?.data?.title}
                </p>
              </div>
              <h4
                className="card-title mb-0 flex-grow-1 fw-bold"
                style={{
                  color: "#2A4153",
                }}
              // {...cardHeaderProps}
              >
                {/* {cardHeaderTitle} */}
              </h4>
              {/* {rightCardHeaderComponent} */}
            </div>
            <div className="mx-4 py-4">
              <div className="col-12 mb-4">
                <PrimaryInput
                  isRequired
                  name="title"
                  label="Schedule title"
                  placeholder="Enter schedule title"
                  value={data?.data?.title}
                  isDisabled={isLoading}
                  isReadOnly={true}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>

              <div className="col-12">
                <PrimaryTextarea
                  isRequired
                  name="description"
                  label={"Description"}
                  placeholder="Enter quick description of the schedule...."
                  size={"lg"}
                  rows={7}
                  value={data?.data?.description}
                  isReadOnly={true}
                  isDisabled={isLoading}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>
                
              <div className="row mx-1 my-4">
                <div className="col-md-6 col-sm-12">
                  <PrimaryInput
                    isRequired
                    type="date"
                    name="starts_at"
                    label="Start date"
                    value={data?.data?.starts_at}
                    isDisabled={isLoading}
                    isReadOnly={true}
                    style={{
                      backgroundColor: "#F2FAFC",
                      borderRadius: 0,
                      borderColor: "#CAECF3",
                    }}
                  />
                </div>

                <div className="col-md-6 col-sm-12">
                  <PrimaryInput
                    isRequired
                    type="date"
                    name="ends_at"
                    label="End date"
                    value={data?.data?.ends_at}
                    isDisabled={isLoading}
                    isReadOnly={true}
                    style={{
                      backgroundColor: "#F2FAFC",
                      borderRadius: 0,
                      borderColor: "#CAECF3",
                    }}
                  />
                </div>
              </div>

              <div className="col-12 mb-4">
                <PrimaryInput
                  isRequired
                  type="text"
                  name="location"
                  label="Location"
                  value={data?.data?.location}
                  isDisabled={isLoading}
                  isReadOnly={true}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
    </ContentBodyContainer>
  );
};
