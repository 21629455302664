import { useToast } from "@chakra-ui/react";
import { PrimaryTextarea, PrimaryInput, PrimaryButton } from "components";
import { useNavigate } from "react-router-dom";
import { ContentBodyContainer } from "../../home";
import { useProject } from "store/projects";
import { useSendMutation} from "store/email";
import { useFormik } from "formik";
import { SendEmailScheme } from "validations/email";
import { resolveApiError } from "utilities";

export const SendEmail = () => {
  const navigate = useNavigate();
  const toast = useToast({ position: "top-right" });
  const projectId: number = useProject().project?.id;
  const [request, { isLoading }] = useSendMutation();
  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: { title: "", message: "", project_id: projectId },
    validationSchema: SendEmailScheme(),
    onSubmit: () => initRequest(),
  });

  const initRequest = () => {
    request(values).unwrap().then((res) => {
      toast({ title: "Email", description: res?.response, status: "success" });
      navigate("/emails")
    }).catch((error) => {
        toast({ title: "Request Failed", description: resolveApiError(error), status: "error" });
    });
  }

  return (
    <ContentBodyContainer
      title="Email"
    >
        <div className="col-md-12 col-sm-12">
          <div className="card custom-card">
            <div className="px-3 pt-3 align-items-center d-flex border-bottom">
              {/* {leftCardHeaderComponent} */}
              <div className="mb-0 flex-grow-1 ">
                <p
                  className="fs-5 fw-bold"
                  style={{
                    color: "#2A4153",
                  }}
                >
                  Send a message
                </p>
              </div>
              <h4
                className="card-title mb-0 flex-grow-1 fw-bold"
                style={{
                  color: "#2A4153",
                }}
              // {...cardHeaderProps}
              >
                {/* {cardHeaderTitle} */}
              </h4>
              {/* {rightCardHeaderComponent} */}
            </div>
            <div className="mx-4 py-4">
              <div className="col-12 mb-4">
                <PrimaryInput
                  name="title"
                  label="Subject"
                  placeholder="Enter subject of the email"
                  value={values?.title}
                  error={Boolean(errors.title && touched.title)}
                  bottomText={errors.title}
                  isDisabled={isLoading}
                  onChange={handleChange}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>

              <div className="col-12">
                <PrimaryTextarea
                  name="message"
                  label={"Message"}
                  placeholder="Enter message..."
                  size={"lg"}
                  rows={7}
                  value={values?.message}
                  error={Boolean(errors.message && touched.message)}
                  bottomText={errors.message}
                  onChange={handleChange}
                  isDisabled={isLoading}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>
             
              <div className="col-12 mt-4">
                <PrimaryButton
                  className="btn btn-primary mb-3"
                  type="submit"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                  style={{
                    width: "200px",
                    padding: "22px 48px 22px 48px",
                    borderRadius: 0,
                  }}
                >
                  Send
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
    </ContentBodyContainer>
  );
};
