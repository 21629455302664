import { PrimarySelect, PrimarySelectProp, PrimaryInput } from "components";
import { useState } from 'react';

interface TrainingNameSelectProps extends PrimarySelectProp {}

export const TrainingNameSelect: React.FC<TrainingNameSelectProps> = ({ ...rest }) => {
    const [newValue, setNewValue] = useState('');
  
    const handleNewValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewValue(event.target.value);
    };
  return (
    <>
    <PrimarySelect
      label="Training Name"
      placeholder="Select your answer..."
      {...rest}
    >
      <option value="Value-based holistic community development">Value-based holistic community development</option>
      <option value="Cornerstones/Social Capital">Cornerstones/Social Capital</option>
      <option value="Gender">Gender</option>
      <option value="Youth Development">Youth Development</option>
      <option value="Business Planning">Business Planning</option>
      <option value="Financial Management">Financial Management</option>
      <option value="Product Processing">Product Processing</option>
      <option value="Enterprise Development">Enterprise Development</option>
      <option value="Project Management">Project Management</option>
      <option value="Value Chain/Link">Value Chain/Link</option>
      <option value="Resource Management">Resource Management</option>
      <option value="Business Management">Business Management</option>
      <option value="Marketing">Marketing</option>
      <option value="Technical Assistance">Technical Assistance</option>
      <option value="Advocacy">Advocacy</option>
      <option value="Basic Literacy">Basic Literacy</option>
      <option value="Vocational Skill Development">Vocational Skill Development</option>
      <option value="Co-Learning/Co-Mentoring">Co-Learning/Co-Mentoring</option>
      <option value="SHG Savings & Credit">SHG Savings & Credit</option>
      <option value="SHG Management">SHG Management</option>
      <option value="Crop Management">Crop Management</option>
      <option value="Disaster Risk Reduction (DRR)">Disaster Risk Reduction (DRR)</option>
      <option value="Climate/Env/Agro-ecology">Climate/Env/Agro-ecology</option>
      <option value="Livestock Management/IAM">Livestock Management/IAM</option>
      <option value="Human Health & Nutrition">Human Health & Nutrition</option>
      <option value="Capacity Building/Assessment">Capacity Building/Assessment</option>
      <option value="Livestock Nutrition/Breeding">Livestock Nutrition/Breeding</option>
      <option value="Training Of Trainers (TOT)">Training Of Trainers (TOT)</option>
      <option value="PSRP">PSRP</option>
      <option value="Livestock Health/CAVE">Livestock Health/CAVE</option>
      <option value="Horticulture">Horticulture</option>
      <option value="others">Others, Specify</option> {/* Updated value */}
    </PrimarySelect>
    {rest.value === 'others' && ( /* Updated condition */
        <PrimaryInput className="mt-4"
          type="text"
          value={newValue}
          onChange={handleNewValueChange}
          placeholder="Please specify..."
          style={{
            backgroundColor: "#F2FAFC",
            borderRadius: 0,
            borderColor: "#CAECF3",
          }}
        />
      )}
    </>
  );
};
