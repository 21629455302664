import { useEffect } from "react";
import { CustomPasswordInput } from 'components';
import { AuthFormSectionContainer } from 'containers';
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useResetMutation } from "store/auth/api";
import { validationError } from "utilities";
import { ResetPasswordValidationSchema } from "validations";
import { useParams } from "react-router-dom";


export const PasswordScreen = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [request, { isLoading }] = useResetMutation();
    const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
        initialValues: { password: '', confirm: '', token: token ?? '' },
        validationSchema: ResetPasswordValidationSchema,
        onSubmit: () => initRequest()
    })

    useEffect(() => {
        return () => {
            if (token && token.length > 50) {
                setFieldValue('token', token)
            } else {
                toast.error("Invalid request, kindly initiate a new password reset to update your password!");
                navigate('/auth/login');
            }
        }
    }, [token])

    const initRequest = () => {
        
        request(values).unwrap().then((response) => {
            // console.log(response);
            toast.success("Password Updated Successfully!");
            navigate("/auth/login");
        }).catch((error) => {
            // console.log(error);
            toast.error(error?.message ?? validationError(error?.data?.response));
        });
    }
    console.log(errors);
    return (
        <AuthFormSectionContainer
            title={"Reset Password?"}
            subTitle={"Your new password must be different from previous used password!"}
            bottomText={"Wait, I remember my password, "}
            bottomActionText={"Sign in"}
            bottomActionRoute={"/auth/login"}
            buttonProps={{
                value: "Reset Password",
                isLoading: isLoading,
                onClick: () => handleSubmit(),
                type: "button",
            }}
        >
            <CustomPasswordInput
                isRequired
                name={"password"}
                label={"Password"}
                placeholder={"Password"}
                value={values.password}
                error={Boolean(errors.password && touched.password)}
                bottomText={errors.password}
                onChange={handleChange}
            />

            <CustomPasswordInput
                isRequired
                name={"confirm"}
                label={"Confirm Password"}
                placeholder={"Confirm Password"}
                value={values.confirm}
                error={Boolean(errors.confirm && touched.confirm)}
                bottomText={errors.confirm}
                onChange={handleChange}
            />

        </AuthFormSectionContainer>
    );
}