import { Button, ButtonProps, useToast } from "@chakra-ui/react";
import { Indicator } from "@store/indicators";
import {
  ChakraAlertDialog,
  ChakraAlertDialogProps,
  IndicatorNameSelect,
  PrimaryInput,
  TargetYearSelect
} from "components";
import { useFormik } from "formik";
import { ChakraProviderLoader } from "providers";
import { useEffect, useState } from "react";
import {  useGetIndicatorsQuery, useAddIndicatorMutation } from "store/indicators";
import { useProject } from "store/projects";
import { resolveApiError } from "utilities";

export interface IndicatorsFormDialogProps extends ChakraAlertDialogProps {
  useButton?: boolean;
  indicator?: Indicator;
  children?: string | React.ReactElement;
  buttonProps?: ButtonProps;
}

export const IndicatorsFormDialog: React.FC<IndicatorsFormDialogProps> = ({
  indicator,
  useButton = false,
  children,
  buttonProps,
  onClose = () => {},
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const toast = useToast({ position: "top-right" });
  const projectId: number = useProject().project.id;
  const [request] = useAddIndicatorMutation ();
  const { isLoading } = useGetIndicatorsQuery({
    
    query: "",
    project_id: projectId,
});
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    touched,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      project_id: projectId,
      name: "",
      annual_target: "",
      actual_target: "",
      target_year: "",
      created_at: "",
    },
    onSubmit: () => initRequest(),
  });

  useEffect(() => {
    if (indicator) setFieldValue("indicator_id", indicator?.id);
  }, [indicator]);

  const project = useProject().project;

  const initRequest = () => {
    const payload: any = {
      ...values,
    };
    request(payload)
      .unwrap()
      .then((res) => {
        // console.log(res);
        toast({
          title: "Indicator Added",
          description: res?.response,
          status: "success",
        });
        resetForm({}); // reset form
        initOnClose();
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Request Failed",
          description: resolveApiError(error),
          status: "error",
        });
      });
  };

  const initOnClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <ChakraProviderLoader>
      {useButton && (
        <Button
          onClick={() => setShow(true)}
          {...buttonProps}
          _hover={{ bg: "#bbc7ca" }}
        >
          {children}
        </Button>
      )}
      <ChakraAlertDialog
        title={"Add Indicator Record"}
        size={"xl"}
        proceedButtonProps={{ colorScheme: "teal" }}
        proceedButtonDefaultChild={"Create"}
        isOpen={rest?.isOpen ? rest?.isOpen : show}
        onProceed={handleSubmit}
        onClose={initOnClose}
        isProceeding={isLoading}
        {...rest}
      >
        <div className="row g-2">

        <div className="col-12 mb-3">
            <IndicatorNameSelect
              isRequired
              name="name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              bottomText={errors.name}
              onChange={handleChange}
              isDisabled={isLoading}
              style={{
                backgroundColor: "#F2FAFC",
                borderRadius: 0,
                borderColor: "#CAECF3",
              }}
            />
          </div>

          <div className="col-12 mb-3">
            <PrimaryInput
              isRequired
              name="actual_target"
              label="Achieved"
              placeholder="Enter target"
              value={values.actual_target}
              error={Boolean(touched.actual_target && errors.actual_target)}
              bottomText={errors.actual_target}
              onChange={handleChange}
              isDisabled={isLoading}
              style={{
                backgroundColor: "#F2FAFC",
                borderRadius: 0,
                borderColor: "#CAECF3",
              }}
            />
          </div>
          
        <div className="col-12 mb-3">
            <PrimaryInput
              isRequired
              name="annual_target"
              label="Annual Target"
              placeholder="Enter target"
              value={values.annual_target}
              error={Boolean(touched.annual_target && errors.annual_target)}
              bottomText={errors.annual_target}
              onChange={handleChange}
              isDisabled={isLoading}
              style={{
                backgroundColor: "#F2FAFC",
                borderRadius: 0,
                borderColor: "#CAECF3",
              }}
            />
          </div>

          <div className="col-12 mb-3">
            <TargetYearSelect
              isRequired
              name="target_year"
              value={values.target_year}
              error={Boolean(touched.target_year && errors.target_year)}
              bottomText={errors.target_year}
              onChange={handleChange}
              isDisabled={isLoading}
              style={{
                backgroundColor: "#F2FAFC",
                borderRadius: 0,
                borderColor: "#CAECF3",
              }}
            />
          </div>

         
        </div>
      </ChakraAlertDialog>
    </ChakraProviderLoader>
  );
};
