import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Intervention } from 'store/intervention';

export const useAllHistoryColumn = () => {
  return useMemo<MRT_ColumnDef<Intervention>[]>(
    () => [
      {
        header: "Name",
        accessorFn: (row) => `${row.name}`,
        Cell: ({ row }) => (
          <>{_.startCase(row.original.name)}</>
        ),
      },
      {
        header: "Description",
        accessorFn: (row) => `${row.description}`,
      },      
      {
        header: "Date Created",
        accessorFn: (row) => `${moment(row.created_at).format('MMMM Do, YYYY')}`,
      },
    ],
    []
  );
};