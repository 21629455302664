import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Story } from 'store/stories';

export const useStoriesColumn = () => {
    return useMemo<MRT_ColumnDef<Story>[]>(
      () => [
        {
          header: "Title",
          accessorFn: (row) => `${row.title}`,
          Cell: ({ row }) => (
            <Link
              to={"/stories/view/" + (row.original as Story).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(row.original.title)}
            </Link>
          ),
        },

        {
          header: "Description",
          accessorFn: (row) => `${row.description}`,
        },
        {
          header: "Uploaded By",
          accessorFn: (row) => `${row.uploaded_by}`,
        },
        {
            header: "View Image",
            accessorFn: (row) => (
              <Link
                to={row.link ?? ''}
                target='_blank'
                className="text-decoration-underline"
              >
                {row.link ? row.link.substring(0, 55)+'...' : ''}
              </Link>
            )
        },
      ],
      []
    );
};