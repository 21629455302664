import { Button, useToast } from "@chakra-ui/react";
import { EditInput, ThemeTable } from "components";
import { useFormik } from "formik";
import { useEffect } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaPen, FaTrash } from "react-icons/fa";
import { MdOutlineAddCircleOutline, MdPersonAddAlt1 } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteFarmerMutation, useEditFarmerMutation, useGetFarmerInfoQuery } from "store/farmers";
import { useProject } from "store/projects";
import { currencyFormatter, resolveApiError } from "utilities";
import FrameOne from "../../../assets/images/Frame_1302-transformed.png";
import FrameTwo from "../../../assets/images/Frame_1303-transformed.png";
import { ContentBodyContainer } from "../../home";
import { AssignGroupDialog } from "../farmers/components/assignGroup";
import { AssignInterventionDialog } from "../farmers/components/assignIntervention";
import { useAllHistoryColumn } from "./components";

export const ViewFarmers = () => {
  const navigate = useNavigate();
  const projectId: number = useProject().project?.id;
  const pathArray: string[] = useLocation().pathname.trim().split("/")
  const farmerId = pathArray[pathArray.length - 1]
  const { data: farmer, isLoading,  refetch } = useGetFarmerInfoQuery({ project_id: projectId, farmer_id: farmerId  });
  const [disableFarmer] = useDeleteFarmerMutation();
  const toast = useToast({ position: "top-right" });
  const interventionHistory = farmer?.data?.farmer_interventions.map((data: any) => (Object.assign({}, ...data.intervention)))
  const [editFarmer] = useEditFarmerMutation();
  const currentInterventions = interventionHistory?.map((data: any) => ({id: data.id }))

  const initDelete = () => {
    let payload = { project_id: projectId, farmers: [farmerId] }
    disableFarmer(payload).unwrap().then((response) => {
      let msg = "Deleted successfully"
      toast({ title: "Farmer", description: msg, status: "success" })
      navigate("/farmers");
    }).catch((error) => {
      let msg = resolveApiError(error?.data?.response)
      toast({ title: "Request Failed", description: msg, status: "error"})
    });
  }

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: { ...farmer?.data, ...{ farmer_id: farmerId }},
    onSubmit: () => editFarmerRequest(),
  });

  useEffect(() => {
    if (farmer?.data) Object.keys(farmer?.data).forEach((key) => setFieldValue(key, farmer?.data[key]))
  }, [farmer, setFieldValue])

  const editFarmerRequest = () => {
    (["creator", "farmer_interventions", "farmer_groups",
      "farmer_groups_count", "farmer_interventions_count"
    ]).map(data => delete values[data])

    editFarmer(values).unwrap().then((res) => {
      refetch()
      toast({ title: "Farmer", description: res?.response, status: "success" });
    }).catch((error) => {
        toast({ title: "Request Failed", description: resolveApiError(error), status: "error" });
    });
  };

  return (
    <ContentBodyContainer
      title="View Households"
      routesRule={"viewFarmers"}
      rightCardHeaderComponent={
        <div className="row g-3 mb-0 align-items-center">
          <div className="col-auto">
            <Button
              colorScheme="teal"
              onClick={ () => initDelete() }
              leftIcon={
                <FaTrash size={12} />
              }
              className={"fw-bold"}
              fontSize={"sm"}
              backgroundColor={"red"}
              color={"#fff"}
              borderRadius={0}
              padding={"12px, 20px, 12px, 20px"}
              _hover={{ bg: "#bbc7ca" }}
              transition={"background-color 0.5s ease-in-out"}
            >
              Delete Household
            </Button>
          </div>
        </div>
      }
    >
        <div className="col-lg-8">
          <div className="card custom-card">
            <div className="px-3 pt-3 align-items-center d-flex border-bottom">
              {/* {leftCardHeaderComponent} */}
              <div className="mb-0 flex-grow-1 ">
                <p
                  className="fs-5 fw-bold"
                  style={{
                    color: "#2A4153",
                  }}
                >
                  Household Information
                </p>
              </div>
              <h4
                className="card-title mb-0 flex-grow-1 fw-bold"
                style={{
                  color: "#2A4153",
                }}
                // {...cardHeaderProps}
              >
                {/* {cardHeaderTitle} */}
              </h4>
              {/* {rightCardHeaderComponent} */}
            </div>
            <div className="mx-4 pt-1">
              <table>
                <tbody>
                  <tr>
                    <td className="fw-bold" style={{ minWidth: "150px" }}>
                      Last Name
                    </td>
                    <td className="p-2">
                      <EditInput
                        isRequired
                        name="last_name"
                        placeholder="Last name"
                        onChange={handleChange}
                        value={values.last_name}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">First Name</td>
                    <td className="p-2">
                      <EditInput
                        isRequired
                        name="first_name"
                        placeholder="First name"
                        onChange={handleChange}
                        value={values.first_name}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Phone Number</td>
                    <td className="p-2"> 
                      <EditInput
                          isRequired
                          name="farmer_phone"
                          placeholder="Farmer phone"
                          onChange={handleChange}
                          value={values.farmer_phone}
                        />
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Gender</td>
                    <td className="p-2">
                      <EditInput
                          isRequired
                          name="farmer_gender"
                          placeholder="Farmer gender"
                          onChange={handleChange}
                          value={values.farmer_gender}
                        />
                     </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Group Name</td>
                    <td className="p-2">
                      <EditInput
                          isRequired
                          name="group_name"
                          placeholder="Farmer group"
                          onChange={handleChange}
                          value={values.group_name}
                        />
                      </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">State</td>
                    <td className="p-2">
                      <EditInput
                          name="state"
                          placeholder="State"
                          onChange={handleChange}
                          value={values.state}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">LGA</td>
                    <td className="p-2">
                      <EditInput
                          name="lga"
                          placeholder="LGA"
                          onChange={handleChange}
                          value={values.lga}
                      />
                     </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Address</td>
                    <td className="p-2 ">
                      <EditInput
                          isRequired
                          name="farmer_address"
                          placeholder="Address"
                          onChange={handleChange}
                          value={values.farmer_address}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row g-3 pt-4 pb-4 align-items-center">
                <div className="col-auto">
                  <Button
                    colorScheme="teal"
                    onClick={() => editFarmerRequest()}
                    leftIcon={ <FaPen size={13} /> }
                    className={"fw-bold"}
                    fontSize={"sm"}
                    backgroundColor={"#7AD0E2"}
                    color={"#fff"}
                    borderRadius={0}
                    padding={"15px"}
                    _hover={{ bg: "#bbc7ca" }}
                    transition={"background-color 0.5s ease-in-out"}
                  >Save Edit</Button>
                  </div>
                <div className="col-auto">
                  <AssignInterventionDialog
                    requiredId={farmerId}
                    useButton={true}
                    intervention={currentInterventions}
                    buttonProps={{
                      leftIcon: <MdOutlineAddCircleOutline size={12} />,
                      fontSize: "sm",
                      className: "fw-bold",
                      backgroundColor: "#2A4153",
                      color: "#fff",
                      borderRadius: 0,
                      padding: "12px, 20px, 12px, 20px",
                      transition: "background-color 0.5s ease-in-out",
                    }}
                    onClose={refetch}
                  >
                    Assign Intervention
                  </AssignInterventionDialog>
                </div>

                <div className="col-auto">
                  <AssignGroupDialog
                      requiredId={farmerId}
                      useButton={true}
                      buttonProps={{
                        leftIcon: (
                          <MdOutlineAddCircleOutline size={12} />
                        ),
                        fontSize: "sm",
                        className: "fw-bold",
                        backgroundColor: "#7AD0E2",
                        color: "#fff",
                        borderRadius: 0,
                        padding: "12px, 20px, 12px, 20px",
                        transition: "background-color 0.5s ease-in-out", // Add transition property
                      }}
                      onClose={refetch}
                    >
                      Assign To Group
                  </AssignGroupDialog>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
            <div className="card card-animate">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="avatar-sm flex-shrink-0 mt-5">
                    <div
                      style={{
                        backgroundColor: "#FFD914",
                        borderRadius: "50%",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "48px",
                        height: "48px",
                      }}
                    >
                      <MdPersonAddAlt1 size={24} className="svg-light" />
                    </div>
                  </div>
                  <div className="flex-grow-1 overflow-hidden text-end">
                    <p className="fs-5 fw-medium text-dark text-truncate mb-3">
                      Assigned Interventions
                    </p>
                    <h4 className="fs-1 fw-bold text-dark mb-5">
                      {currencyFormatter(farmer?.data ? 
                        farmer?.data?.farmer_interventions_count : 0)}
                    </h4>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card card-animate">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="avatar-sm flex-shrink-0 mt-5">
                  <div
                  style={{
                    backgroundColor: "#B00074",
                    borderRadius: "50%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <BsFillPatchCheckFill size={24} className="svg-light" />
                </div>
                  </div>
                  <div className="flex-grow-1 overflow-hidden text-end">
                    <p className="fs-5 fw-medium text-dark text-truncate mb-3">
                      Assigned SH Groups
                    </p>
                    <h4 className="fs-1 fw-bold text-dark mb-5">
                    {currencyFormatter(farmer?.data ? 
                        farmer?.data?.farmer_groups_count : 0)}
                    </h4>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
          </div>
          
          <div className="col-xl-12 col-md-12 mb-4">
          <div
            className="animate"
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              borderRadius: "10px",
            }}
          >
            <iframe
              title="View_Participants"
              src="https://app.powerbi.com/view?r=eyJrIjoiMzdhZDk1NjAtMTBjNC00NDk3LWFhNzYtOTM5ZmZiY2E1NGI3IiwidCI6ImRhNmE2OWUzLTNiY2QtNGY0NC1hMzQwLWFlYWQwY2ZkYTA4ZiIsImMiOjN9"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
                fontFamily: "Montserrat, sans-serif !important",
              }}
            ></iframe>
          </div>
        </div>

        <div className="col-xl-12">
          <h2 className="mt-3 mb-3 fw-bold" style={{color: "rgb(41, 41, 42)"}}>Intervention History</h2>
          <ThemeTable
            data={interventionHistory ?? []}
            columns={useAllHistoryColumn() as any}
            isLoading={isLoading}
            onRefetch={refetch}
          />
        </div>
    </ContentBodyContainer>
  );
};
