import { Report } from 'store/reports';
import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UserInfo } from 'store/user';

export const useAllReportsColumn = () => {
    return useMemo<MRT_ColumnDef<Report>[]>(
      () => [
        {
          header: "Uploaded By",
          accessorFn: (row) => `${row.fname} ${row.lname}`,
          Cell: ({ row }) => (
            <Link
              to={"/users/view/" + (row.original as unknown as UserInfo).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(`${row.original.fname} ${row.original.lname}`)} 
            </Link>
          ),
        },
        
        {
          header: "Farmers' Target",
          accessorFn: (row) => `${row.shf_target}`,
        },
        {
          header: "Farmers' Achievement",
          accessorFn: (row) => `${row.shf_achievement}`,
        },
        {
          header: "Groups' Target",
          accessorFn: (row) => `${row.shg_target}`,
        },
        {
          header: "Groups' Achievement",
          accessorFn: (row) => `${row.shg_achievement}`,
        },
        {
          header: "Outcomes",
          accessorFn: (row) => `${row.outcomes}`,
        },
        {
          header: "Remarks",
          accessorFn: (row) => `${row.remarks}`,
        },
        {
          header: "Challenges",
          accessorFn: (row) => `${row.challenges}`,
        },
        {
          header: "Male Farmers Trained",
          accessorFn: (row) => `${row.no_male_farmers}`,
        },
        {
          header: "Female Farmers Trained",
          accessorFn: (row) => `${row.no_female_farmers}`,
        },
        {
          header: "Date Added",
          accessorFn: (row) => `${moment(row.created_at).format('MMMM Do, YYYY')}`,
        },
      ],
      []
    );
};