import { AlertDefaultBox, PrimaryInput } from 'components';
import { AuthFormSectionContainer } from 'containers';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForgotMutation } from 'store/auth/api';
import { validationError } from 'utilities';
import { ForgotValidationSchema } from 'validations';

export const ForgotScreen = () => {
    const navigate = useNavigate();
    const [request, { isLoading }] = useForgotMutation();
    const { values, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: { email: '' },
        validationSchema: ForgotValidationSchema,
        onSubmit: () => initRequest()
    })

    const initRequest = () => {
        request(values).unwrap().then((response) => {
            // console.log(response);
            toast.success("Your reset token will be sent to your email address to recover your password!");
        }).catch((error) => {
            console.log(error);
            toast.error(error?.message ?? validationError(error?.data?.response));
        });
    }

    return (
        <AuthFormSectionContainer
            title={"Forgot Password?"}
            subTitle={"Enter your email and instructions will be sent to you!"}
            bottomText={"Wait, I remember my password, "}
            bottomActionText={"Sign in"}
            bottomActionRoute={"/auth/login"}
            buttonProps={{
                value: "Reset Password",
                isLoading: isLoading,
                onClick: () => handleSubmit()
            }}
        >

            <AlertDefaultBox
                status='warning'
                title={"Are you a member without an email?"}
                body={"Kindly reach out to your admin to update your password if no email address was attached to your member profile."}
            />
            
            <PrimaryInput
                isRequired
                label={"Email Address"}
                placeholder={"ex: myemailexample@example.com"}
                value={values.email}
                error={Boolean(errors.email)}
                bottomText={errors.email}
                onChange={({ target }) => setFieldValue('email', target.value)}
            />

        </AuthFormSectionContainer>
    )
}