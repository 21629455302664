
export interface StateOption {
    label: string;
    value: string;
}
export const NigeriaStates = [
    { "state": { "name": "Abia State", "id": 1, "locals": [{ "name": "Aba South", "id": 1 }, { "name": "Arochukwu", "id": 2 }, { "name": "Bende", "id": 3 }, { "name": "Ikwuano", "id": 4 }, { "name": "Isiala Ngwa North", "id": 5 }, { "name": "Isiala Ngwa South", "id": 6 }, { "name": "Isuikwuato", "id": 7 }, { "name": "Obi Ngwa", "id": 8 }, { "name": "Ohafia", "id": 9 }, { "name": "Osisioma", "id": 10 }, { "name": "Ugwunagbo", "id": 11 }, { "name": "Ukwa East", "id": 12 }, { "name": "Ukwa West", "id": 13 }, { "name": "Umuahia North", "id": 14 }, { "name": "Umuahia South", "id": 15 }, { "name": "Umu Nneochi", "id": 16 }] } }, 
    { "state": { "name": "Adamawa State", "id": 2, "locals": [{ "name": "Fufure", "id": 1 }, { "name": "Ganye", "id": 2 }, { "name": "Gayuk", "id": 3 }, { "name": "Gombi", "id": 4 }, { "name": "Grie", "id": 5 }, { "name": "Hong", "id": 6 }, { "name": "Jada", "id": 7 }, { "name": "Lamurde", "id": 8 }, { "name": "Madagali", "id": 9 }, { "name": "Maiha", "id": 10 }, { "name": "Mayo Belwa", "id": 11 }, { "name": "Michika", "id": 12 }, { "name": "Mubi North", "id": 13 }, { "name": "Mubi South", "id": 14 }, { "name": "Numan", "id": 15 }, { "name": "Shelleng", "id": 16 }, { "name": "Song", "id": 17 }, { "name": "Toungo", "id": 18 }, { "name": "Yola North", "id": 19 }, { "name": "Yola South", "id": 20 }] } }, 
    { "state": { "name": "Akwa Ibom State", "id": 3, "locals": [{ "name": "Eastern Obolo", "id": 1 }, { "name": "Eket", "id": 2 }, { "name": "Esit Eket", "id": 3 }, { "name": "Essien Udim", "id": 4 }, { "name": "Etim Ekpo", "id": 5 }, { "name": "Etinan", "id": 6 }, { "name": "Ibeno", "id": 7 }, { "name": "Ibesikpo Asutan", "id": 8 }, { "name": "Ibiono-Ibom", "id": 9 }, { "name": "Ika", "id": 10 }, { "name": "Ikono", "id": 11 }, { "name": "Ikot Abasi", "id": 12 }, { "name": "Ikot Ekpene", "id": 13 }, { "name": "Ini", "id": 14 }, { "name": "Itu", "id": 15 }, { "name": "Mbo", "id": 16 }, { "name": "Mkpat-Enin", "id": 17 }, { "name": "Nsit-Atai", "id": 18 }, { "name": "Nsit-Ibom", "id": 19 }, { "name": "Nsit-Ubium", "id": 20 }, { "name": "Obot Akara", "id": 21 }, { "name": "Okobo", "id": 22 }, { "name": "Onna", "id": 23 }, { "name": "Oron", "id": 24 }, { "name": "Oruk Anam", "id": 25 }, { "name": "Udung-Uko", "id": 26 }, { "name": "Ukanafun", "id": 27 }, { "name": "Uruan", "id": 28 }, { "name": "Urue-Offong Oruko", "id": 29 }, { "name": "Uyo", "id": 30 }] } }, 
    { "state": { "name": "Anambra State", "id": 4, "locals": [{ "name": "Anambra East", "id": 1 }, { "name": "Anambra West", "id": 2 }, { "name": "Anaocha", "id": 3 }, { "name": "Awka North", "id": 4 }, { "name": "Awka South", "id": 5 }, { "name": "Ayamelum", "id": 6 }, { "name": "Dunukofia", "id": 7 }, { "name": "Ekwusigo", "id": 8 }, { "name": "Idemili North", "id": 9 }, { "name": "Idemili South", "id": 10 }, { "name": "Ihiala", "id": 11 }, { "name": "Njikoka", "id": 12 }, { "name": "Nnewi North", "id": 13 }, { "name": "Nnewi South", "id": 14 }, { "name": "Ogbaru", "id": 15 }, { "name": "Onitsha North", "id": 16 }, { "name": "Onitsha South", "id": 17 }, { "name": "Orumba North", "id": 18 }, { "name": "Orumba South", "id": 19 }, { "name": "Oyi", "id": 20 }] } }, 
    { "state": { "name": "Bauchi State", "id": 5, "locals": [{ "name": "Bauchi", "id": 1 }, { "name": "Bogoro", "id": 2 }, { "name": "Damban", "id": 3 }, { "name": "Darazo", "id": 4 }, { "name": "Dass", "id": 5 }, { "name": "Gamawa", "id": 6 }, { "name": "Ganjuwa", "id": 7 }, { "name": "Giade", "id": 8 }, { "name": "Itas Gadau", "id": 9 }, { "name": "Jama'are", "id": 10 }, { "name": "Katagum", "id": 11 }, { "name": "Kirfi", "id": 12 }, { "name": "Misau", "id": 13 }, { "name": "Ningi", "id": 14 }, { "name": "Shira", "id": 15 }, { "name": "Tafawa Balewa", "id": 16 }, { "name": "Toro", "id": 17 }, { "name": "Warji", "id": 18 }, { "name": "Zaki", "id": 19 }] } }, 
    { "state": { "name": "Bayelsa State", "id": 6, "locals": [{ "name": "Ekeremor", "id": 1 }, { "name": "Kolokuma Opokuma", "id": 2 }, { "name": "Nembe", "id": 3 }, { "name": "Ogbia", "id": 4 }, { "name": "Sagbama", "id": 5 }, { "name": "Southern Ijaw", "id": 6 }, { "name": "Yenagoa", "id": 7 }] } }, 
    { "state": { "name": "Benue State", "id": 7, "locals": [{ "name": "Apa", "id": 1 }, { "name": "Ado", "id": 2 }, { "name": "Buruku", "id": 3 }, { "name": "Gboko", "id": 4 }, { "name": "Guma", "id": 5 }, { "name": "Gwer East", "id": 6 }, { "name": "Gwer West", "id": 7 }, { "name": "Katsina-Ala", "id": 8 }, { "name": "Konshisha", "id": 9 }, { "name": "Kwande", "id": 10 }, { "name": "Logo", "id": 11 }, { "name": "Makurdi", "id": 12 }, { "name": "Obi", "id": 13 }, { "name": "Ogbadibo", "id": 14 }, { "name": "Ohimini", "id": 15 }, { "name": "Oju", "id": 16 }, { "name": "Okpokwu", "id": 17 }, { "name": "Oturkpo", "id": 18 }, { "name": "Tarka", "id": 19 }, { "name": "Ukum", "id": 20 }, { "name": "Ushongo", "id": 21 }, { "name": "Vandeikya", "id": 22 }] } }, 
    { "state": { "name": "Borno State", "id": 8, "locals": [{ "name": "Askira Uba", "id": 1 }, { "name": "Bama", "id": 2 }, { "name": "Bayo", "id": 3 }, { "name": "Biu", "id": 4 }, { "name": "Chibok", "id": 5 }, { "name": "Damboa", "id": 6 }, { "name": "Dikwa", "id": 7 }, { "name": "Gubio", "id": 8 }, { "name": "Guzamala", "id": 9 }, { "name": "Gwoza", "id": 10 }, { "name": "Hawul", "id": 11 }, { "name": "Jere", "id": 12 }, { "name": "Kaga", "id": 13 }, { "name": "Kala Balge", "id": 14 }, { "name": "Konduga", "id": 15 }, { "name": "Kukawa", "id": 16 }, { "name": "Kwaya Kusar", "id": 17 }, { "name": "Mafa", "id": 18 }, { "name": "Magumeri", "id": 19 }, { "name": "Maiduguri", "id": 20 }, { "name": "Marte", "id": 21 }, { "name": "Mobbar", "id": 22 }, { "name": "Monguno", "id": 23 }, { "name": "Ngala", "id": 24 }, { "name": "Nganzai", "id": 25 }, { "name": "Shani", "id": 26 }] } }, 
    { "state": { "name": "Cross River State", "id": 9, "locals": [{ "name": "Akamkpa", "id": 1 }, { "name": "Akpabuyo", "id": 2 }, { "name": "Bakassi", "id": 3 }, { "name": "Bekwarra", "id": 4 }, { "name": "Biase", "id": 5 }, { "name": "Boki", "id": 6 }, { "name": "Calabar Municipal", "id": 7 }, { "name": "Calabar South", "id": 8 }, { "name": "Etung", "id": 9 }, { "name": "Ikom", "id": 10 }, { "name": "Obanliku", "id": 11 }, { "name": "Obubra", "id": 12 }, { "name": "Obudu", "id": 13 }, { "name": "Odukpani", "id": 14 }, { "name": "Ogoja", "id": 15 }, { "name": "Yakuur", "id": 16 }, { "name": "Yala", "id": 17 }] } }, 
    { "state": { "name": "Delta State", "id": 10, "locals": [{ "name": "Aniocha South", "id": 1 }, { "name": "Bomadi", "id": 2 }, { "name": "Burutu", "id": 3 }, { "name": "Ethiope East", "id": 4 }, { "name": "Ethiope West", "id": 5 }, { "name": "Ika North East", "id": 6 }, { "name": "Ika South", "id": 7 }, { "name": "Isoko North", "id": 8 }, { "name": "Isoko South", "id": 9 }, { "name": "Ndokwa East", "id": 10 }, { "name": "Ndokwa West", "id": 11 }, { "name": "Okpe", "id": 12 }, { "name": "Oshimili North", "id": 13 }, { "name": "Oshimili South", "id": 14 }, { "name": "Patani", "id": 15 }, { "name": "Sapele", "id": 16 }, { "name": "Udu", "id": 17 }, { "name": "Ughelli North", "id": 18 }, { "name": "Ughelli South", "id": 19 }, { "name": "Ukwuani", "id": 20 }, { "name": "Uvwie", "id": 21 }, { "name": "Warri North", "id": 22 }, { "name": "Warri South", "id": 23 }, { "name": "Warri South West", "id": 24 }] } }, 
    { "state": { "name": "Ebonyi State", "id": 11, "locals": [{ "name": "Afikpo North", "id": 1 }, { "name": "Afikpo South", "id": 2 }, { "name": "Ebonyi", "id": 3 }, { "name": "Ezza North", "id": 4 }, { "name": "Ezza South", "id": 5 }, { "name": "Ikwo", "id": 6 }, { "name": "Ishielu", "id": 7 }, { "name": "Ivo", "id": 8 }, { "name": "Izzi", "id": 9 }, { "name": "Ohaozara", "id": 10 }, { "name": "Ohaukwu", "id": 11 }, { "name": "Onicha", "id": 12 }] } }, 
    { "state": { "name": "Edo State", "id": 12, "locals": [{ "name": "Egor", "id": 1 }, { "name": "Esan Central", "id": 2 }, { "name": "Esan North-East", "id": 3 }, { "name": "Esan South-East", "id": 4 }, { "name": "Esan West", "id": 5 }, { "name": "Etsako Central", "id": 6 }, { "name": "Etsako East", "id": 7 }, { "name": "Etsako West", "id": 8 }, { "name": "Igueben", "id": 9 }, { "name": "Ikpoba Okha", "id": 10 }, { "name": "Orhionmwon", "id": 11 }, { "name": "Oredo", "id": 12 }, { "name": "Ovia North-East", "id": 13 }, { "name": "Ovia South-West", "id": 14 }, { "name": "Owan East", "id": 15 }, { "name": "Owan West", "id": 16 }, { "name": "Uhunmwonde", "id": 17 }] } }, 
    { "state": { "name": "Ekiti State", "id": 13, "locals": [{ "name": "Efon", "id": 1 }, { "name": "Ekiti East", "id": 2 }, { "name": "Ekiti South-West", "id": 3 }, { "name": "Ekiti West", "id": 4 }, { "name": "Emure", "id": 5 }, { "name": "Gbonyin", "id": 6 }, { "name": "Ido Osi", "id": 7 }, { "name": "Ijero", "id": 8 }, { "name": "Ikere", "id": 9 }, { "name": "Ikole", "id": 10 }, { "name": "Ilejemeje", "id": 11 }, { "name": "Irepodun Ifelodun", "id": 12 }, { "name": "Ise Orun", "id": 13 }, { "name": "Moba", "id": 14 }, { "name": "Oye", "id": 15 }] } }, 
    { "state": { "name": "Enugu State", "id": 14, "locals": [{ "name": "Awgu", "id": 1 }, { "name": "Enugu East", "id": 2 }, { "name": "Enugu North", "id": 3 }, { "name": "Enugu South", "id": 4 }, { "name": "Ezeagu", "id": 5 }, { "name": "Igbo Etiti", "id": 6 }, { "name": "Igbo Eze North", "id": 7 }, { "name": "Igbo Eze South", "id": 8 }, { "name": "Isi Uzo", "id": 9 }, { "name": "Nkanu East", "id": 10 }, { "name": "Nkanu West", "id": 11 }, { "name": "Nsukka", "id": 12 }, { "name": "Oji River", "id": 13 }, { "name": "Udenu", "id": 14 }, { "name": "Udi", "id": 15 }, { "name": "Uzo Uwani", "id": 16 }] } }, 
    { "state": { "name": "FCT", "id": 15, "locals": [{ "name": "Bwari", "id": 1 }, { "name": "Gwagwalada", "id": 2 }, { "name": "Kuje", "id": 3 }, { "name": "Kwali", "id": 4 }, { "name": "Municipal Area Council", "id": 5 }] } }, 
    { "state": { "name": "Gombe State", "id": 16, "locals": [{ "name": "Balanga", "id": 1 }, { "name": "Billiri", "id": 2 }, { "name": "Dukku", "id": 3 }, { "name": "Funakaye", "id": 4 }, { "name": "Gombe", "id": 5 }, { "name": "Kaltungo", "id": 6 }, { "name": "Kwami", "id": 7 }, { "name": "Nafada", "id": 8 }, { "name": "Shongom", "id": 9 }, { "name": "Yamaltu Deba", "id": 10 }] } }, 
    { "state": { "name": "Imo State", "id": 17, "locals": [{ "name": "Ahiazu Mbaise", "id": 1 }, { "name": "Ehime Mbano", "id": 2 }, { "name": "Ezinihitte", "id": 3 }, { "name": "Ideato North", "id": 4 }, { "name": "Ideato South", "id": 5 }, { "name": "Ihitte Uboma", "id": 6 }, { "name": "Ikeduru", "id": 7 }, { "name": "Isiala Mbano", "id": 8 }, { "name": "Isu", "id": 9 }, { "name": "Mbaitoli", "id": 10 }, { "name": "Ngor Okpala", "id": 11 }, { "name": "Njaba", "id": 12 }, { "name": "Nkwerre", "id": 13 }, { "name": "Nwangele", "id": 14 }, { "name": "Obowo", "id": 15 }, { "name": "Oguta", "id": 16 }, { "name": "Ohaji Egbema", "id": 17 }, { "name": "Okigwe", "id": 18 }, { "name": "Orlu", "id": 19 }, { "name": "Orsu", "id": 20 }, { "name": "Oru East", "id": 21 }, { "name": "Oru West", "id": 22 }, { "name": "Owerri Municipal", "id": 23 }, { "name": "Owerri North", "id": 24 }, { "name": "Owerri West", "id": 25 }, { "name": "Unuimo", "id": 26 }] } }, 
    { "state": { "name": "Jigawa State", "id": 18, "locals": [{ "name": "Babura", "id": 1 }, { "name": "Biriniwa", "id": 2 }, { "name": "Birnin Kudu", "id": 3 }, { "name": "Buji", "id": 4 }, { "name": "Dutse", "id": 5 }, { "name": "Gagarawa", "id": 6 }, { "name": "Garki", "id": 7 }, { "name": "Gumel", "id": 8 }, { "name": "Guri", "id": 9 }, { "name": "Gwaram", "id": 10 }, { "name": "Gwiwa", "id": 11 }, { "name": "Hadejia", "id": 12 }, { "name": "Jahun", "id": 13 }, { "name": "Kafin Hausa", "id": 14 }, { "name": "Kazaure", "id": 15 }, { "name": "Kiri Kasama", "id": 16 }, { "name": "Kiyawa", "id": 17 }, { "name": "Kaugama", "id": 18 }, { "name": "Maigatari", "id": 19 }, { "name": "Malam Madori", "id": 20 }, { "name": "Miga", "id": 21 }, { "name": "Ringim", "id": 22 }, { "name": "Roni", "id": 23 }, { "name": "Sule Tankarkar", "id": 24 }, { "name": "Taura", "id": 25 }, { "name": "Yankwashi", "id": 26 }] } }, 
    { "state": { "name": "Kaduna State", "id": 19, "locals": [{ "name": "Chikun", "id": 1 }, { "name": "Giwa", "id": 2 }, { "name": "Igabi", "id": 3 }, { "name": "Ikara", "id": 4 }, { "name": "Jaba", "id": 5 }, { "name": "Jema'a", "id": 6 }, { "name": "Kachia", "id": 7 }, { "name": "Kaduna North", "id": 8 }, { "name": "Kaduna South", "id": 9 }, { "name": "Kagarko", "id": 10 }, { "name": "Kajuru", "id": 11 }, { "name": "Kaura", "id": 12 }, { "name": "Kauru", "id": 13 }, { "name": "Kubau", "id": 14 }, { "name": "Kudan", "id": 15 }, { "name": "Lere", "id": 16 }, { "name": "Makarfi", "id": 17 }, { "name": "Sabon Gari", "id": 18 }, { "name": "Sanga", "id": 19 }, { "name": "Soba", "id": 20 }, { "name": "Zangon Kataf", "id": 21 }, { "name": "Zaria", "id": 22 }] } }, 
    { "state": { "name": "Kano State", "id": 20, "locals": [{ "name": "Albasu", "id": 1 }, { "name": "Bagwai", "id": 2 }, { "name": "Bebeji", "id": 3 }, { "name": "Bichi", "id": 4 }, { "name": "Bunkure", "id": 5 }, { "name": "Dala", "id": 6 }, { "name": "Dambatta", "id": 7 }, { "name": "Dawakin Kudu", "id": 8 }, { "name": "Dawakin Tofa", "id": 9 }, { "name": "Doguwa", "id": 10 }, { "name": "Fagge", "id": 11 }, { "name": "Gabasawa", "id": 12 }, { "name": "Garko", "id": 13 }, { "name": "Garun Mallam", "id": 14 }, { "name": "Gaya", "id": 15 }, { "name": "Gezawa", "id": 16 }, { "name": "Gwale", "id": 17 }, { "name": "Gwarzo", "id": 18 }, { "name": "Kabo", "id": 19 }, { "name": "Kano Municipal", "id": 20 }, { "name": "Karaye", "id": 21 }, { "name": "Kibiya", "id": 22 }, { "name": "Kiru", "id": 23 }, { "name": "Kumbotso", "id": 24 }, { "name": "Kunchi", "id": 25 }, { "name": "Kura", "id": 26 }, { "name": "Madobi", "id": 27 }, { "name": "Makoda", "id": 28 }, { "name": "Minjibir", "id": 29 }, { "name": "Nasarawa", "id": 30 }, { "name": "Rano", "id": 31 }, { "name": "Rimin Gado", "id": 32 }, { "name": "Rogo", "id": 33 }, { "name": "Shanono", "id": 34 }, { "name": "Sumaila", "id": 35 }, { "name": "Takai", "id": 36 }, { "name": "Tarauni", "id": 37 }, { "name": "Tofa", "id": 38 }, { "name": "Tsanyawa", "id": 39 }, { "name": "Tudun Wada", "id": 40 }, { "name": "Ungogo", "id": 41 }, { "name": "Warawa", "id": 42 }, { "name": "Wudil", "id": 43 }] } }, 
    { "state": { "name": "Katsina State", "id": 21, "locals": [{ "name": "Batagarawa", "id": 1 }, { "name": "Batsari", "id": 2 }, { "name": "Baure", "id": 3 }, { "name": "Bindawa", "id": 4 }, { "name": "Charanchi", "id": 5 }, { "name": "Dandume", "id": 6 }, { "name": "Danja", "id": 7 }, { "name": "Dan Musa", "id": 8 }, { "name": "Daura", "id": 9 }, { "name": "Dutsi", "id": 10 }, { "name": "Dutsin Ma", "id": 11 }, { "name": "Faskari", "id": 12 }, { "name": "Funtua", "id": 13 }, { "name": "Ingawa", "id": 14 }, { "name": "Jibia", "id": 15 }, { "name": "Kafur", "id": 16 }, { "name": "Kaita", "id": 17 }, { "name": "Kankara", "id": 18 }, { "name": "Kankia", "id": 19 }, { "name": "Katsina", "id": 20 }, { "name": "Kurfi", "id": 21 }, { "name": "Kusada", "id": 22 }, { "name": "Mai'Adua", "id": 23 }, { "name": "Malumfashi", "id": 24 }, { "name": "Mani", "id": 25 }, { "name": "Mashi", "id": 26 }, { "name": "Matazu", "id": 27 }, { "name": "Musawa", "id": 28 }, { "name": "Rimi", "id": 29 }, { "name": "Sabuwa", "id": 30 }, { "name": "Safana", "id": 31 }, { "name": "Sandamu", "id": 32 }, { "name": "Zango", "id": 33 }] } }, 
    { "state": { "name": "Kebbi State", "id": 22, "locals": [{ "name": "Arewa Dandi", "id": 1 }, { "name": "Argungu", "id": 2 }, { "name": "Augie", "id": 3 }, { "name": "Bagudo", "id": 4 }, { "name": "Birnin Kebbi", "id": 5 }, { "name": "Bunza", "id": 6 }, { "name": "Dandi", "id": 7 }, { "name": "Fakai", "id": 8 }, { "name": "Gwandu", "id": 9 }, { "name": "Jega", "id": 10 }, { "name": "Kalgo", "id": 11 }, { "name": "Koko Besse", "id": 12 }, { "name": "Maiyama", "id": 13 }, { "name": "Ngaski", "id": 14 }, { "name": "Sakaba", "id": 15 }, { "name": "Shanga", "id": 16 }, { "name": "Suru", "id": 17 }, { "name": "Wasagu Danko", "id": 18 }, { "name": "Yauri", "id": 19 }, { "name": "Zuru", "id": 20 }] } }, 
    { "state": { "name": "Kogi State", "id": 23, "locals": [{ "name": "Ajaokuta", "id": 1 }, { "name": "Ankpa", "id": 2 }, { "name": "Bassa", "id": 3 }, { "name": "Dekina", "id": 4 }, { "name": "Ibaji", "id": 5 }, { "name": "Idah", "id": 6 }, { "name": "Igalamela Odolu", "id": 7 }, { "name": "Ijumu", "id": 8 }, { "name": "Kabba Bunu", "id": 9 }, { "name": "Kogi", "id": 10 }, { "name": "Lokoja", "id": 11 }, { "name": "Mopa Muro", "id": 12 }, { "name": "Ofu", "id": 13 }, { "name": "Ogori Magongo", "id": 14 }, { "name": "Okehi", "id": 15 }, { "name": "Okene", "id": 16 }, { "name": "Olamaboro", "id": 17 }, { "name": "Omala", "id": 18 }, { "name": "Yagba East", "id": 19 }, { "name": "Yagba West", "id": 20 }] } }, 
    { "state": { "name": "Kwara State", "id": 24, "locals": [{ "name": "Baruten", "id": 1 }, { "name": "Edu", "id": 2 }, { "name": "Ekiti", "id": 3 }, { "name": "Ifelodun", "id": 4 }, { "name": "Ilorin East", "id": 5 }, { "name": "Ilorin South", "id": 6 }, { "name": "Ilorin West", "id": 7 }, { "name": "Irepodun", "id": 8 }, { "name": "Isin", "id": 9 }, { "name": "Kaiama", "id": 10 }, { "name": "Moro", "id": 11 }, { "name": "Offa", "id": 12 }, { "name": "Oke Ero", "id": 13 }, { "name": "Oyun", "id": 14 }, { "name": "Pategi", "id": 15 }] } }, 
    { "state": { "name": "Lagos State", "id": 25, "locals": [{ "name": "Ajeromi-Ifelodun", "id": 1 }, { "name": "Alimosho", "id": 2 }, { "name": "Amuwo-Odofin", "id": 3 }, { "name": "Apapa", "id": 4 }, { "name": "Badagry", "id": 5 }, { "name": "Epe", "id": 6 }, { "name": "Eti Osa", "id": 7 }, { "name": "Ibeju-Lekki", "id": 8 }, { "name": "Ifako-Ijaiye", "id": 9 }, { "name": "Ikeja", "id": 10 }, { "name": "Ikorodu", "id": 11 }, { "name": "Kosofe", "id": 12 }, { "name": "Lagos Island", "id": 13 }, { "name": "Lagos Mainland", "id": 14 }, { "name": "Mushin", "id": 15 }, { "name": "Ojo", "id": 16 }, { "name": "Oshodi-Isolo", "id": 17 }, { "name": "Shomolu", "id": 18 }, { "name": "Surulere", "id": 19 }] } }, 
    { "state": { "name": "Nasarawa State", "id": 26, "locals": [{ "name": "Awe", "id": 1 }, { "name": "Doma", "id": 2 }, { "name": "Karu", "id": 3 }, { "name": "Keana", "id": 4 }, { "name": "Keffi", "id": 5 }, { "name": "Kokona", "id": 6 }, { "name": "Lafia", "id": 7 }, { "name": "Nasarawa", "id": 8 }, { "name": "Nasarawa Egon", "id": 9 }, { "name": "Obi", "id": 10 }, { "name": "Toto", "id": 11 }, { "name": "Wamba", "id": 12 }] } }, 
    { "state": { "name": "Niger State", "id": 27, "locals": [{ "name": "Agwara", "id": 1 }, { "name": "Bida", "id": 2 }, { "name": "Borgu", "id": 3 }, { "name": "Bosso", "id": 4 }, { "name": "Chanchaga", "id": 5 }, { "name": "Edati", "id": 6 }, { "name": "Gbako", "id": 7 }, { "name": "Gurara", "id": 8 }, { "name": "Katcha", "id": 9 }, { "name": "Kontagora", "id": 10 }, { "name": "Lapai", "id": 11 }, { "name": "Lavun", "id": 12 }, { "name": "Magama", "id": 13 }, { "name": "Mariga", "id": 14 }, { "name": "Mashegu", "id": 15 }, { "name": "Mokwa", "id": 16 }, { "name": "Moya", "id": 17 }, { "name": "Paikoro", "id": 18 }, { "name": "Rafi", "id": 19 }, { "name": "Rijau", "id": 20 }, { "name": "Shiroro", "id": 21 }, { "name": "Suleja", "id": 22 }, { "name": "Tafa", "id": 23 }, { "name": "Wushishi", "id": 24 }] } }, 
    { "state": { "name": "Ogun State", "id": 28, "locals": [{ "name": "Abeokuta South", "id": 1 }, { "name": "Ado-Odo Ota", "id": 2 }, { "name": "Egbado North", "id": 3 }, { "name": "Egbado South", "id": 4 }, { "name": "Ewekoro", "id": 5 }, { "name": "Ifo", "id": 6 }, { "name": "Ijebu East", "id": 7 }, { "name": "Ijebu North", "id": 8 }, { "name": "Ijebu North East", "id": 9 }, { "name": "Ijebu Ode", "id": 10 }, { "name": "Ikenne", "id": 11 }, { "name": "Imeko Afon", "id": 12 }, { "name": "Ipokia", "id": 13 }, { "name": "Obafemi Owode", "id": 14 }, { "name": "Odeda", "id": 15 }, { "name": "Odogbolu", "id": 16 }, { "name": "Ogun Waterside", "id": 17 }, { "name": "Remo North", "id": 18 }, { "name": "Shagamu", "id": 19 }] } }, 
    { "state": { "name": "Ondo State", "id": 29, "locals": [{ "name": "Akoko North-West", "id": 1 }, { "name": "Akoko South-West", "id": 2 }, { "name": "Akoko South-East", "id": 3 }, { "name": "Akure North", "id": 4 }, { "name": "Akure South", "id": 5 }, { "name": "Ese Odo", "id": 6 }, { "name": "Idanre", "id": 7 }, { "name": "Ifedore", "id": 8 }, { "name": "Ilaje", "id": 9 }, { "name": "Ile Oluji Okeigbo", "id": 10 }, { "name": "Irele", "id": 11 }, { "name": "Odigbo", "id": 12 }, { "name": "Okitipupa", "id": 13 }, { "name": "Ondo East", "id": 14 }, { "name": "Ondo West", "id": 15 }, { "name": "Ose", "id": 16 }, { "name": "Owo", "id": 17 }] } }, 
    { "state": { "name": "Osun State", "id": 30, "locals": [{ "name": "Atakunmosa West", "id": 1 }, { "name": "Aiyedaade", "id": 2 }, { "name": "Aiyedire", "id": 3 }, { "name": "Boluwaduro", "id": 4 }, { "name": "Boripe", "id": 5 }, { "name": "Ede North", "id": 6 }, { "name": "Ede South", "id": 7 }, { "name": "Ife Central", "id": 8 }, { "name": "Ife East", "id": 9 }, { "name": "Ife North", "id": 10 }, { "name": "Ife South", "id": 11 }, { "name": "Egbedore", "id": 12 }, { "name": "Ejigbo", "id": 13 }, { "name": "Ifedayo", "id": 14 }, { "name": "Ifelodun", "id": 15 }, { "name": "Ila", "id": 16 }, { "name": "Ilesa East", "id": 17 }, { "name": "Ilesa West", "id": 18 }, { "name": "Irepodun", "id": 19 }, { "name": "Irewole", "id": 20 }, { "name": "Isokan", "id": 21 }, { "name": "Iwo", "id": 22 }, { "name": "Obokun", "id": 23 }, { "name": "Odo Otin", "id": 24 }, { "name": "Ola Oluwa", "id": 25 }, { "name": "Olorunda", "id": 26 }, { "name": "Oriade", "id": 27 }, { "name": "Orolu", "id": 28 }, { "name": "Osogbo", "id": 29 }] } }, 
    { "state": { "name": "Oyo State", "id": 31, "locals": [{ "name": "Akinyele", "id": 1 }, { "name": "Atiba", "id": 2 }, { "name": "Atisbo", "id": 3 }, { "name": "Egbeda", "id": 4 }, { "name": "Ibadan North", "id": 5 }, { "name": "Ibadan North-East", "id": 6 }, { "name": "Ibadan North-West", "id": 7 }, { "name": "Ibadan South-East", "id": 8 }, { "name": "Ibadan South-West", "id": 9 }, { "name": "Ibarapa Central", "id": 10 }, { "name": "Ibarapa East", "id": 11 }, { "name": "Ibarapa North", "id": 12 }, { "name": "Ido", "id": 13 }, { "name": "Irepo", "id": 14 }, { "name": "Iseyin", "id": 15 }, { "name": "Itesiwaju", "id": 16 }, { "name": "Iwajowa", "id": 17 }, { "name": "Kajola", "id": 18 }, { "name": "Lagelu", "id": 19 }, { "name": "Ogbomosho North", "id": 20 }, { "name": "Ogbomosho South", "id": 21 }, { "name": "Ogo Oluwa", "id": 22 }, { "name": "Olorunsogo", "id": 23 }, { "name": "Oluyole", "id": 24 }, { "name": "Ona Ara", "id": 25 }, { "name": "Orelope", "id": 26 }, { "name": "Ori Ire", "id": 27 }, { "name": "Oyo", "id": 28 }, { "name": "Oyo East", "id": 29 }, { "name": "Saki East", "id": 30 }, { "name": "Saki West", "id": 31 }, { "name": "Surulere", "id": 32 }] } }, 
    { "state": { "name": "Plateau State", "id": 32, "locals": [{ "name": "Barkin Ladi", "id": 1 }, { "name": "Bassa", "id": 2 }, { "name": "Jos East", "id": 3 }, { "name": "Jos North", "id": 4 }, { "name": "Jos South", "id": 5 }, { "name": "Kanam", "id": 6 }, { "name": "Kanke", "id": 7 }, { "name": "Langtang South", "id": 8 }, { "name": "Langtang North", "id": 9 }, { "name": "Mangu", "id": 10 }, { "name": "Mikang", "id": 11 }, { "name": "Pankshin", "id": 12 }, { "name": "Qua'an Pan", "id": 13 }, { "name": "Riyom", "id": 14 }, { "name": "Shendam", "id": 15 }, { "name": "Wase", "id": 16 }] } }, 
    { "state": { "name": "Rivers State", "id": 33, "locals": [{ "name": "Ahoada East", "id": 1 }, { "name": "Ahoada West", "id": 2 }, { "name": "Akuku-Toru", "id": 3 }, { "name": "Andoni", "id": 4 }, { "name": "Asari-Toru", "id": 5 }, { "name": "Bonny", "id": 6 }, { "name": "Degema", "id": 7 }, { "name": "Eleme", "id": 8 }, { "name": "Emuoha", "id": 9 }, { "name": "Etche", "id": 10 }, { "name": "Gokana", "id": 11 }, { "name": "Ikwerre", "id": 12 }, { "name": "Khana", "id": 13 }, { "name": "Obio Akpor", "id": 14 }, { "name": "Ogba Egbema Ndoni", "id": 15 }, { "name": "Ogu Bolo", "id": 16 }, { "name": "Okrika", "id": 17 }, { "name": "Omuma", "id": 18 }, { "name": "Opobo Nkoro", "id": 19 }, { "name": "Oyigbo", "id": 20 }, { "name": "Port Harcourt", "id": 21 }, { "name": "Tai", "id": 22 }] } }, 
    { "state": { "name": "Sokoto State", "id": 34, "locals": [{ "name": "Bodinga", "id": 1 }, { "name": "Dange Shuni", "id": 2 }, { "name": "Gada", "id": 3 }, { "name": "Goronyo", "id": 4 }, { "name": "Gudu", "id": 5 }, { "name": "Gwadabawa", "id": 6 }, { "name": "Illela", "id": 7 }, { "name": "Isa", "id": 8 }, { "name": "Kebbe", "id": 9 }, { "name": "Kware", "id": 10 }, { "name": "Rabah", "id": 11 }, { "name": "Sabon Birni", "id": 12 }, { "name": "Shagari", "id": 13 }, { "name": "Silame", "id": 14 }, { "name": "Sokoto North", "id": 15 }, { "name": "Sokoto South", "id": 16 }, { "name": "Tambuwal", "id": 17 }, { "name": "Tangaza", "id": 18 }, { "name": "Tureta", "id": 19 }, { "name": "Wamako", "id": 20 }, { "name": "Wurno", "id": 21 }, { "name": "Yabo", "id": 22 }] } }, 
    { "state": { "name": "Taraba State", "id": 35, "locals": [{ "name": "Bali", "id": 1 }, { "name": "Donga", "id": 2 }, { "name": "Gashaka", "id": 3 }, { "name": "Gassol", "id": 4 }, { "name": "Ibi", "id": 5 }, { "name": "Jalingo", "id": 6 }, { "name": "Karim Lamido", "id": 7 }, { "name": "Kumi", "id": 8 }, { "name": "Lau", "id": 9 }, { "name": "Sardauna", "id": 10 }, { "name": "Takum", "id": 11 }, { "name": "Ussa", "id": 12 }, { "name": "Wukari", "id": 13 }, { "name": "Yorro", "id": 14 }, { "name": "Zing", "id": 15 }] } }, 
    { "state": { "name": "Yobe State", "id": 36, "locals": [{ "name": "Bursari", "id": 1 }, { "name": "Damaturu", "id": 2 }, { "name": "Fika", "id": 3 }, { "name": "Fune", "id": 4 }, { "name": "Geidam", "id": 5 }, { "name": "Gujba", "id": 6 }, { "name": "Gulani", "id": 7 }, { "name": "Jakusko", "id": 8 }, { "name": "Karasuwa", "id": 9 }, { "name": "Machina", "id": 10 }, { "name": "Nangere", "id": 11 }, { "name": "Nguru", "id": 12 }, { "name": "Potiskum", "id": 13 }, { "name": "Tarmuwa", "id": 14 }, { "name": "Yunusari", "id": 15 }, { "name": "Yusufari", "id": 16 }] } }, 
    { "state": { "name": "Zamfara State", "id": 37, "locals": [{ "name": "Bakura", "id": 1 }, { "name": "Birnin Magaji Kiyaw", "id": 2 }, { "name": "Bukkuyum", "id": 3 }, { "name": "Bungudu", "id": 4 }, { "name": "Gummi", "id": 5 }, { "name": "Gusau", "id": 6 }, { "name": "Kaura Namoda", "id": 7 }, { "name": "Maradun", "id": 8 }, { "name": "Maru", "id": 9 }, { "name": "Shinkafi", "id": 10 }, { "name": "Talata Mafara", "id": 11 }, { "name": "Chafe", "id": 12 }, { "name": "Zurmi", "id": 13 }] } }]

export const StatesOptions: StateOption[] = NigeriaStates.map((data) => ({ 
    label: data.state.name, value: `${data.state.id}` 
}));

export const getStateLocals = (id: number): StateOption[] => {
    const states = NigeriaStates.filter((data) => data.state.id === id);
    // return empty array
    if(!states.length) return [];
    // return locals if states 
    return (states[0].state.locals).map((data) => ({label: data.name, value: data.name}));
}