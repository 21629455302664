import { createApi } from '@reduxjs/toolkit/query/react';
import { convertObjectToURLParams } from 'utilities/general';
import { axiosBaseQuery } from '../../utilities/axiosQuery/axiosBaseQuery';
import { baseUrl } from '../../utilities/requests';
import { IResponse } from '../auth/interface';
import { CreateStoryPayload, PaginationResponse, DeleteStoriesPayload, EditStoryPayload, FetchStoriesPayload, ViewStoryPayload } from './interface';


export const storyApi = createApi({
    reducerPath: 'storyApi',
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/` }),
    tagTypes: ['myProfile'],
    endpoints: (builder) => ({
        stories: builder.query<PaginationResponse, FetchStoriesPayload>({
            query: (payload) => ({
                url: `projects/stories?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        story: builder.query<IResponse, ViewStoryPayload>({
            query: (payload) => ({
                url: `projects/stories/info?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        create: builder.mutation<IResponse, CreateStoryPayload>({
            query: (payload) => ({
                url: 'projects/stories/create',
                headers: { 'Content-Type': 'multipart/form-data'},
                method: 'POST',
                body: payload
            }),
        }),
        edit: builder.mutation<IResponse, EditStoryPayload>({
            query: (payload) => ({
                url: 'projects/stories/edit',
                method: 'POST',
                body: payload
            }),
        }),
        delete: builder.mutation<IResponse, DeleteStoriesPayload>({
            query: (payload) => ({
                url: 'projects/stories/remove',
                method: 'POST',
                body: payload
            }),
        }),
    })
});

export const {
    useStoriesQuery,
    useStoryQuery,
    useCreateMutation,
    useEditMutation,
    useDeleteMutation,
} = storyApi;

