import React from "react";
import { Stack, StackProps } from "@chakra-ui/react";
import {
  AuthHeaderText,
  AuthHeaderTextProps,
  PrimaryButton,
  PrimaryButtonProps,
} from "components";
import { Link } from "react-router-dom";

export interface AuthFormSectionContainerProps extends AuthHeaderTextProps {
  buttonProps?: PrimaryButtonProps;
  bottomText?: string;
  bottomActionText?: string;
  bottomActionRoute?: string;
  bottomActionClick?: () => void;
  containerProps?: StackProps;
  children?: React.ReactElement | React.ReactNode;
}
export const AuthFormSectionContainer: React.FC<
  AuthFormSectionContainerProps
> = ({
  buttonProps,
  bottomText,
  bottomActionText,
  bottomActionRoute = "/",
  bottomActionClick,
  containerProps,
  children,
  ...rest
}) => {
  return (
    <Stack
      {...containerProps}
      align="center"
      justify="center"
      minHeight="100vh" 
    >
      <AuthHeaderText title={rest.title} subTitle={rest.subTitle} />

      <Stack spacing={4} paddingTop={4} width="100%" maxWidth="400px"> 
        {children}

        <div className="mt-4">
          <PrimaryButton
            width={"100%"}
            type="submit"
            colorScheme="blue" 
            {...buttonProps}
          />
        </div>
      </Stack>

      {bottomText && bottomActionText ? (
        <div className="mt-5 text-center">
          <p className="mb-0">
            {bottomText}&nbsp;
            <Link
              to={bottomActionRoute}
              onClick={
                bottomActionClick &&
                ((e) => {
                  e.preventDefault();
                  bottomActionClick();
                })
              }
              className="fw-semibold text-primary"
            >
              {bottomActionText}
            </Link>
          </p>
        </div>
      ) : null}
    </Stack>
  );
};
