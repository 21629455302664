import { createApi } from '@reduxjs/toolkit/query/react';
import { convertObjectToURLParams } from 'utilities/general';
import { axiosBaseQuery } from '../../utilities/axiosQuery/axiosBaseQuery';
import { baseUrl } from '../../utilities/requests';
import { IResponse } from '../auth/interface';
import { AddTrainingPayload, ChurchTrainingsResponse, DeleteTrainingsPayload, EditTrainingPayload, FetchTrainingsPayload, TrainingSuccessResponse,  } from './interface';


export const trainingApi = createApi({
    reducerPath: 'trainingApi',
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/` }),
    tagTypes: ['myProfile'],
    endpoints: (builder) => ({
        getTrainings: builder.query<ChurchTrainingsResponse, FetchTrainingsPayload>({
            query: (payload) => ({
                url: `projects/trainings?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        fetchTrainings: builder.mutation<ChurchTrainingsResponse, FetchTrainingsPayload>({
            query: (payload) => ({
                url: `projects/trainings?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        addTraining: builder.mutation<TrainingSuccessResponse, AddTrainingPayload>({
            query: (payload) => ({
                url: 'projects/trainings/create',
                method: 'POST',
                body: payload
            }),
        }),
        editTraining: builder.mutation<IResponse, EditTrainingPayload>({
            query: (payload) => ({
                url: 'projects/trainings/edit',
                method: 'POST',
                body: payload
            }),
        }),
        deleteTraining: builder.mutation<IResponse, DeleteTrainingsPayload>({
            query: (payload) => ({
                url: 'projects/trainings/remove',
                method: 'POST',
                body: payload
            }),
        }),
        getTrainingInfo: builder.query<IResponse, { project_id: number, Training_id: number|string }>({
            query: (payload) => ({
                url: `projects/trainings/info?${convertObjectToURLParams(payload)}`,
                method: 'GET',
            }),
        }),

    })
});

export const {
    useGetTrainingsQuery,
    useFetchTrainingsMutation,
    useAddTrainingMutation,
    useEditTrainingMutation,
    useDeleteTrainingMutation,
    useGetTrainingInfoQuery,
} = trainingApi;

