import { Button, ButtonProps, useToast } from "@chakra-ui/react";
import { ChakraAlertDialog, ChakraAlertDialogProps, PrimaryMultiSelect, PrimaryMultiSelectOption } from "components";
import { useFormik } from "formik";
import { ChakraProviderLoader } from "providers";
import { useState } from "react";
import { useAssignInterventionMutation } from "store/farmers";
import { Intervention, useGetInterventionsQuery } from "store/intervention";
import { useProject } from "store/projects";
import { resolveApiError } from "utilities";

export interface AssignInterventionDialogProps extends ChakraAlertDialogProps {
  requiredId: number | string;
  useButton?: boolean;
  intervention?: [];
  children?: string | React.ReactElement;
  buttonProps?: ButtonProps;
}

export const AssignInterventionDialog: React.FC<AssignInterventionDialogProps> = ({
  requiredId,
  intervention,
  useButton = false,
  children,
  buttonProps,
  onClose = () => {},
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const toast = useToast({ position: "top-right" });
  const projectId: number = useProject().project.id;
  const [assignIntervention, {isLoading}] = useAssignInterventionMutation();
  const { values, handleChange, setFieldValue } = useFormik({
      initialValues: { farmer_id: requiredId, interventions: [] }, onSubmit: () => initRequest(),
  });

  const { data: interventions } = useGetInterventionsQuery({ project_id: projectId });
  const interventionOptions = (selected?: string[]): PrimaryMultiSelectOption[] => {
    const selectedByValue = (value: string) => Boolean(selected?.includes(value));
    const _interventions = interventions?.data.data ?? [];
    return _interventions.map((data: any) => ({ 
      label: data.name, value: data.id, 
      selected: selectedByValue(data.id) 
    }))
  }

  const initRequest = () => {
    intervention?.map((data) => values.interventions.push(data))
    assignIntervention(values).unwrap().then((response) => {
      let msg = "Assigned successfully";
      toast({ title: "Intervention", description: msg, status: "success" });
    })
    .catch((error) => {
      let msg = resolveApiError(error?.data?.response);
      toast({ title: "Request Failed", description: msg, status: "error" });
    });
  };

  const initOnClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <ChakraProviderLoader>
      {useButton && (
        <Button onClick={() => setShow(true)} {...buttonProps} _hover={{ bg: "#bbc7ca" }}>
          {children}
        </Button>
      )}
      <ChakraAlertDialog
        title={"Assign Intervention"}
        size={"xl"}
        proceedButtonProps={{ colorScheme: "teal" }}
        proceedButtonDefaultChild={"Assign"}
        isOpen={rest?.isOpen ? rest?.isOpen : show}
        onProceed={initRequest}
        onClose={initOnClose}
        isProceeding={isLoading}
        {...rest}
      >
         <div className="row g-2">
          <div className="col-12 d-flex flex-column mb-4">
            <div className="row g-2 mb-3">
              <div className="col">
                <PrimaryMultiSelect
                    name="interventions"
                    isMulti={true}
                    placeholder="Select Intervention"
                    isDisabled={isLoading}
                    onChange={handleChange}
                    options={interventionOptions(values.interventions)}
                    onMultiChange={(selected) => {
                      setFieldValue('interventions', selected.map((v) => ({ id: v.value })));
                    }}
                  />
                  </div>
              </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </ChakraProviderLoader>
  );
};
