import { PrimarySelect, PrimarySelectProp } from "components";

interface IndicatorNameSelectProps extends PrimarySelectProp {}
export const IndicatorNameSelect: React.FC<IndicatorNameSelectProps> = ({
  ...rest
}) => {
  return (
    <PrimarySelect
      label="Indicator Name"
      placeholder="Select Name"
      {...rest}
      
    >
      <option value="Number of households participating in Heifer Programs">Number of households participating in Heifer Programs</option>
      <option value="Proportion of women participants in Heifer Programs/ Proportion of Women Reached">Proportion of women participants in Heifer Programs/ Proportion of Women Reached</option>
      <option value="Percentage of Leadership Positions at the Organization Level Occupied by Women- BTM-GI-05-SDG5">Percentage of Leadership Positions at the Organization Level Occupied by Women- BTM-GI-05-SDG5</option>
      <option value="BTM 2.1: FPO Business Plan are updated and complete">BTM 2.1: FPO Business Plan are updated and complete</option>
      <option value="BTM 1.3 Number of Formal Partnerships Formed as a Result of Heifer Activities">BTM 1.3 Number of Formal Partnerships Formed as a Result of Heifer Activities</option>
      <option value="BTM 01: SDG Proportion of active members in Farmer Producer Organizations">BTM 01: SDG Proportion of active members in Farmer Producer Organizations</option>
      <option value="Number of entities/FAOBs accessing financial products and services">Number of entities/FAOBs accessing financial products and services</option>
      <option value="Proportion of needed capital accessible at the FPO level">Proportion of needed capital accessible at the FPO level</option>
      <option value="Number of SHFs with access to insurance services that seek to mitigate the impact of climate change">Number of SHFs with access to insurance services that seek to mitigate the impact of climate change</option>
      <option value="Value in USD of new public sector commitments and investment leveraged (GI-14-SDG17)">Value in USD of new public sector commitments and investment leveraged (GI-14-SDG17)</option>
      <option value="Value in USD of new private sector commitments and investment leveraged (GI-15-SDG17)">Value in USD of new private sector commitments and investment leveraged (GI-15-SDG17)</option>
    </PrimarySelect>
  );
};
