import { ContentBodyContainer } from "../../home";
import { useEventsColumn } from "./components/eventsColumn";
import { ThemeTable } from "components";
import { FaEye, FaTrash, FaDownload, FaCalendar } from "react-icons/fa";
import { useState } from "react";
import { resolveApiError } from "utilities";
import { useNavigate } from "react-router-dom";
import { useProject } from "store/projects";
import { Button, useToast } from "@chakra-ui/react";
import { useEventsQuery, useDeleteMutation, Event } from "store/event";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from 'store/auth/hooks';

export const EventsScreen = () => {
    const navigate = useNavigate();
    const columns = useEventsColumn();
    const userRole = useAuth().user?.account_type ?? "";
    const projectId: number = useProject().getProject()?.id;
    const { data, isLoading, refetch } = useEventsQuery({ project_id: projectId });
    const toast = useToast({ position: "top-right" });
    const [showModal, setShowModal] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(0);
    const [deleteStory] = useDeleteMutation();
    const schedule = data?.data?.data.map((data) => {
      const creator = data.creator || {};
      const user_info = creator.user_info || {};
      const created = `${user_info.fname || ''} ${user_info.lname || ''}`;
      return {
        title: data.title,
        description: data.description,
        starts_at: data.starts_at,
        ends_at: data.ends_at,
        location: data.location,
        created_by: created,
        id: data.id,
      };
    });
    
    

    const handleDelete = (row: any) => {
        setSelectedEventId(row.original.id);
        setShowModal(true);
      };
    
      const handleConfirmDelete = () => {
        initDelete(selectedEventId);
        setShowModal(false);
      };
    
      const handleCancelDelete = () => {
        setShowModal(false);
      };
    
      const initDelete = (record: number) => {
        let payload = { project_id: projectId, events: [record]}
        deleteStory(payload).unwrap().then((response) => {
          let msg = "Event has been deleted successfully"
          toast({ title: "Event", description: msg, status: "success" })
          refetch();
        }).catch((error) => {
          let msg = resolveApiError(error?.data?.response)
          toast({ title: "Request Failed", description: msg, status: "error"})
        });
      }

    return (
        <ContentBodyContainer 
          title="Schedule"
          routesRule={"Schedule"}
          rightCardHeaderComponent={
            userRole == "admin" ?
            <div className="row g-3 mb-0 align-items-center">
              <div className="col-auto">
                <Button
                  colorScheme="teal"
                  onClick={() => navigate("/schedule/create")}
                  leftIcon={
                    <FaCalendar className="svg-dark" size={12} />
                  }
                  className={"fw-bold"}
                  fontSize={"sm"}
                  backgroundColor={"#7AD0E2"}
                  color={"#000000"}
                  borderRadius={0}
                  padding={"12px, 20px, 12px, 20px"}
                  _hover={{ bg: "#bbc7ca" }}
                  transition={"background-color 0.5s ease-in-out"}
                >
                  Create Event
                </Button>
              </div>
            </div>
            : <></>
          }
        >
            <div className="col-xl-12">
                <ThemeTable
                    data={schedule ?? []}
                    columns={columns as any}
                    isLoading={isLoading}
                    onRefetch={refetch}
                    filename="Schedules data"
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                        <div className="d-flex justify-content-evenly">
                          <div className="touchable pe-2">
                              <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="view-tooltip">View</Tooltip>}
                              >
                              <Button onClick={() => navigate(`/schedule/view/` + (row.original as Event).id)}>
                                  <FaEye size={16} color="#7F8C9F" />
                              </Button>
                              </OverlayTrigger>
                          </div>
                        {  userRole == "admin" ? (
                          <div className="touchable" onClick={() => handleDelete(row)}>
                            <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                            >
                            <div>
                                <FaTrash size={16} color="red" />
                            </div>
                            </OverlayTrigger>
                        </div>
                        ): (<></>)}
                      </div>
                    )}
                />
            </div>

            <Modal show={showModal} onHide={handleCancelDelete}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Event</Modal.Title>
              </Modal.Header>
              <Modal.Body className="fs-5 p-4">Are you sure you want to delete this event?</Modal.Body>
              <Modal.Footer style={{ borderTop: "none" }}>
                <Button
                  variant="secondary"
                  onClick={handleCancelDelete}
                  style={{ backgroundColor: "#cccccc", color: "#ffffff" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={handleConfirmDelete}
                  style={{ backgroundColor: "red", color: "#ffffff" }}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
        </ContentBodyContainer>
    );
};
