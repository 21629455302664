import { Button, useToast } from "@chakra-ui/react";
import { ThemeTable } from "components";
import { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useProject } from "store/projects";
import { useDeleteShgMutation, useGetShgsQuery } from "store/shg";
import { resolveApiError } from "utilities";
import { ContentBodyContainer } from "../../home";
import { useAllShgsColumn } from "./components/recordsColumns";

export const RecordScreen = () => {
  const navigate = useNavigate();
  const columns = useAllShgsColumn();
  const projectId: number = useProject().project?.id;
  const [query, setQuery] = useState("");
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [community, setCommunity] = useState("");
  const { data, isLoading, refetch } = useGetShgsQuery({
     query: query, project_id: projectId,
    state: state, lga: lga, community: community,
  });
  const toast = useToast({ position: "top-right" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteShg] = useDeleteShgMutation();
  const [selectedShgId, setSelectedShgId] = useState(0);
  const records = data?.data?.data.map((data: any) => {
    return Object.assign({}, {
      entity_name: data.entity_name,
      business_plan: data.business_plan,
      financial: data.financial,
      service_provider: data.service_provider,
      value: data.value,
      hold_meeting: data.hold_meeting,
      cash: data.cash,
      asset: data.asset,
      financial_services: data.financial_services,
      how_much_was_accessed: data.how_much_was_accessed,
      conduct_sales: data.conduct_sales,
      commodity_sold: data.commodity_sold,
      quantity_sold: data.quantity_sold,
      measurement_unit: data.measurement_unit,
      entities_engaged: data.entities_engaged,
      entity_details: data.entity_details,
      primary_function: data.primary_function,
      secondary_function: data.secondary_function,
      state: data.state,
      lga: data.lga,
      community: data.community,
      price: data.price,
      comment: data.comment,
      created_at: data.created_at,
      id: data.id,
    })
  })


  const handleDelete = (row: any) => {
    setSelectedShgId(row.original.id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    initDelete(selectedShgId);
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const initDelete = (shg: number) => {
    let payload = { project_id: projectId, group_subs: [shg] }
    deleteShg(payload).unwrap().then((response) => {
      let msg = "Record has been deleted successfully"
      toast({ title: "Record Deleted", description: msg, status: "success" })
      refetch();
    }).catch((error) => {
      let msg = resolveApiError(error?.data?.response)
      toast({ title: "Request Failed", description: msg, status: "error" })
    });
  }


  return (
    <ContentBodyContainer
      title="All Records"
      routesRule={"createRecord"}
    >


      <div className="col-xl-12">
        <ThemeTable
          data={records ?? []}
          columns={columns as any}
          isLoading={isLoading}
          onRefetch={refetch}
          filename="Records data"
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <div className="d-flex justify-content-evenly">
              <div className="touchable" onClick={() => handleDelete(row)}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}

                >
                  <div>
                    <FaTrash size={16} color="red" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          )}
        />
      </div>

      <Modal show={showDeleteModal} onHide={handleCancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Record</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-5 p-4">Are you sure you want to delete this record?</Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            onClick={handleCancelDelete}
            style={{ backgroundColor: "#cccccc", color: "#ffffff" }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleConfirmDelete}
            style={{ backgroundColor: "red", color: "#ffffff" }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </ContentBodyContainer>
  );
};

