import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Email } from 'store/email';
import { useAuth } from 'store/auth/hooks';
import moment from 'moment';

export const useEmailColumn = () => {
    const userRole = useAuth().user?.account_type ?? "";
    return useMemo<MRT_ColumnDef<Email>[]>(
      () => [
        (userRole == "admin" ?
        {
          header: "Sender",
          accessorFn: (row) => row.sender,
        }
        :
        {
          header: "Recipient",
          accessorFn: (row) => `Heifer International`,
        }),
        {
          header: "Subject",
          accessorFn: (row) => `${row.title}`,
          Cell: ({ row }) => (
            <Link
              to={"/emails/view/" + (row.original as Email).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(row.original.title)}
            </Link>
          ),
        },
        {
          header: "Message",
          accessorFn: (row) => `${ row.message.length ? row.message.substring(0, 30)+'...' : row.message}`,
        },
        {
          header: "Date",
          accessorFn: (row) => `${moment(row.date).format('MMMM Do, YYYY')}`,
        }
      ],
      []
    );
};