import { Button, ButtonProps, useToast } from "@chakra-ui/react";
import {
  ChakraAlertDialog,
  ChakraAlertDialogProps,
  PrimaryMultiSelect, PrimaryMultiSelectOption
} from "components";
import { useFormik } from "formik";
import { resolveApiError } from "utilities";
import { useState } from "react";
import { ChakraProviderLoader } from "providers";
import { Farmer, useGetFarmersQuery } from "store/farmers";
import { useProject } from "store/projects";
import { useAddUserMutation } from "store/user";
import { useAssignFarmerMutation } from "store/group";

export interface AssignFarmerDialogProps extends ChakraAlertDialogProps {
  requiredId: number | string;
  useButton?: boolean;
  farmer?: [];
  children?: string | React.ReactElement;
  buttonProps?: ButtonProps;
}

export const AssignFarmerDialog: React.FC<AssignFarmerDialogProps> = ({
  requiredId,
  farmer,
  useButton = false,
  children,
  buttonProps,
  onClose = () => {},
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const toast = useToast({ position: "top-right" });
  const [request, { isLoading }] = useAddUserMutation();
  const projectId: number = useProject().project.id;
  const { data: farmers } = useGetFarmersQuery({ project_id: projectId });
  const [assignFarmer] = useAssignFarmerMutation();
  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: { group_id: requiredId, farmers: [] }, onSubmit: () => initRequest()
  });
  const farmerNames = (selected?: string[]): PrimaryMultiSelectOption[] => {
    const selectedByValue = (value: string) => Boolean(selected?.includes(value));
    const _farmers = farmers?.data.data ?? [];
      return _farmers.map((data: any) => ({
        label: data.first_name + " " + data.last_name,
        value: data.id, selected: selectedByValue(data.id) 
      }));
  };
  
  const initRequest = () => {
    farmer?.map((data) => values.farmers.push(data))
    assignFarmer(values).unwrap().then((response) => {
      let msg = "Assigned successfully"
      toast({ title: "Household", description: msg, status: "success" })
    }).catch((error) => {
      let msg = resolveApiError(error?.data?.response)
      toast({ title: "Request Failed", description: msg, status: "error"})
    });
  }

  const initOnClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <ChakraProviderLoader>
      {useButton && (
        <Button
          onClick={() => setShow(true)}
          {...buttonProps}
          _hover={{ bg: "#bbc7ca" }}
        >
          {children}
        </Button>
      )}
      <ChakraAlertDialog
        title={"Assign To Household"}
        size={"xl"}
        proceedButtonProps={{ colorScheme: "teal" }}
        proceedButtonDefaultChild={"Assign"}
        isOpen={rest?.isOpen ? rest?.isOpen : show}
        onProceed={initRequest}
        onClose={initOnClose}
        isProceeding={isLoading}
        {...rest}
      >
        <div className="row g-2">
        <div className="col-12">
          <PrimaryMultiSelect
            name="farmers"
            isMulti={true}
            placeholder="Select Household"
            isDisabled={isLoading}
            onChange={handleChange}
            options={farmerNames(values.farmers)}
            onMultiChange={(selected) => {
              setFieldValue('farmers', selected.map((v) => ({id: v.value })));
            }}
          />
        </div>

         
        </div>
      </ChakraAlertDialog>
    </ChakraProviderLoader>
  );
};
