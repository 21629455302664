import { PrimarySelect, PrimarySelectProp } from "components";

interface TargetYearSelectProps extends PrimarySelectProp {}
export const TargetYearSelect: React.FC<TargetYearSelectProps> = ({
  ...rest
}) => {
  return (
    <PrimarySelect
      label="Target Year"
      placeholder="Select Year"
      {...rest}
    >
      <option value="FY 23">FY 23</option>
      <option value="FY 24">FY 24</option>
      <option value="FY 25">FY 25</option>
      <option value="FY 26">FY 26</option>
      <option value="FY 27">FY 27</option>
      <option value="FY 28">FY 28</option>
      <option value="FY 29">FY 29</option>
      <option value="FY 30">FY 30</option>
    </PrimarySelect>
  );
};
