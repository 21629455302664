import { PrimarySelect, PrimarySelectProp } from 'components';

interface EngagedEntitiesSelectProps extends PrimarySelectProp {

}
export const EngagedEntitiesSelect: React.FC<EngagedEntitiesSelectProps> = ({
    ...rest
}) => {
    return (
        <PrimarySelect
            {...rest}
        > 
        <option value="Farmer Organization">Farmer Organization</option>
        <option value="Service Provider">Service Provider</option>
        <option value="Public Institution">Public Institution</option>
        </PrimarySelect>
    )
}