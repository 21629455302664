import { AgeCategorySelect, GenderSelect, PrimaryInput, PrimarySelect } from "components";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useAuth } from "store/auth";
import { useGetInterventionsQuery } from "store/intervention";
import { useProject } from "store/projects";
import { communities, locals, states } from "utilities";

export interface HouseholdFilterSystemProps {
  gender?: (value?: string) => void;
  age?: (value?: string) => void;
  state?: (value?: string) => void;
  lga?: (value?: string) => void;
  community?: (value?: string) => void;
  intervention?: (value?: Array<number | string>) => void;
  income?: (value?: string) => void;
  query?: (value?: string) => void;
}

export const HouseholdFilterSystem: React.FC<HouseholdFilterSystemProps> = ({ 
  gender = () => {}, 
  age = () => {}, 
  state = () => {}, 
  lga = () => {}, 
  intervention = () => {}, 
  community = () => {}, 
  income = () => {},
  query = () => {},
}) => {
  const projectId: number = useProject().project.id;
  const { data: interventions } = useGetInterventionsQuery({ project_id: projectId });
  const interventionNames = interventions?.data.data.map((data: { name: any; id: any; }) => {
    return { text: `${data.name}`, props: { value: data.id  }}
  })
  const initRequest = () => { const payload: any = {...values };};
  const { values, handleChange } = useFormik({ 
    initialValues: {
      lga: "", location: "", community: "",
      state: "", farmers: "", query:"",
      gender: "", intervention: "", income: "", age_category: "",
    },onSubmit: async () => initRequest(),
  });

    const { user } = useAuth();
    const userRole = user?.account_type ?? ""
    const[userState, setUserState] = useState("")
    const[userLga, setUserLga] = useState("")

    useEffect(() => {
      if (values.gender) gender(values.gender);
      if (values.age_category) age(values.age_category);
      if (values.state) state(values.state);
      if (values.community) community(values.community);
      if (values.lga) lga(values.lga);
      if (values.intervention) intervention([Number(values.intervention)]);
      if (values.income) income(values.income);
      if (values.query) query(values.query);

      const except = "community_facilitator state_coordinator"
      if (except.includes(userRole)) setUserState(user?.user_info?.state ?? "_");
      if ("community_facilitator".includes(userRole)) setUserLga(user?.user_info?.lga ?? "_");

      if (userState) state(userState);
      if (userLga) lga(userLga);
    }, [values, userState, userLga]);

    return (
      <div className="col-xl-12">
        <div className="row g-3 mb-4">
          <div className="col-auto">
            <PrimarySelect 
              name="state"
              placeholder="Select State"
              options={ states(userState) }
              onChange={handleChange}
              size={"lg"}
              style={{ backgroundColor: "#fff", border: "none", borderRadius: 0 }}
            />
          </div>
          
          <div className="col-auto">
            <PrimarySelect 
              name="lga"
              placeholder="Select Local Gov"
              options={ locals(values.state, userLga) }
              onChange={handleChange}
              size={"lg"}
              style={{ backgroundColor: "#fff", border: "none", borderRadius: 0 }}
            />
          </div>

          <div className="col-auto">
            <PrimarySelect
              name="community"
              placeholder="Select Community"
              options={ communities(values.state, values.lga) }
              onChange={handleChange}
              size={"lg"}
              style={{ backgroundColor: "#fff", borderRadius: 0, border: 0 }}
            />
          </div>

              {/* <div className="col-3">
                <AgeCategorySelect
                  isRequired
                  placeholder="Select Age Category"
                  name="age_category"
                  label=""
                  value={values.age_category}
                  onChange={handleChange}
                  style={{
                    backgroundColor: "#fff", borderRadius: 0, border: 0 
                  }}
                />
              </div> */}

          <div className="col-3">
            <GenderSelect
              isRequired
              name="gender"
              placeholder="Select Gender"
              value={values.gender}
              onChange={handleChange}
              size={"lg"}
              label=""
              style={{
                backgroundColor: "#ffff",
                borderRadius: 0,
                border: 0,
              }}
            />
          </div>


          <div className="col-3">
            <PrimarySelect
              name="intervention"
              placeholder="Select Intervention"
              options={interventionNames}
              value={values.intervention}
              onChange={handleChange}
              size={"lg"}
              style={{
                backgroundColor: "#ffff",
                borderRadius: 0,
                border: 0,
              }}
            />
          </div>

          <div className="col-3">
            <PrimaryInput
              name="income"
              placeholder="Income, ₦"
              value={values.income}
              onChange={handleChange}
              rightComponent={<FaPlus color={"grey"} />}
              size={"lg"}
              style={{
                backgroundColor: "#ffff",
                borderRadius: 0,
                border: 0,
              }}
            />
          </div>
        </div>
      </div>
    ); 
};


