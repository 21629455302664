import { PrimarySelect, PrimarySelectProp } from 'components';

interface EntityDetailsSelectProps extends PrimarySelectProp {

}
export const EntityDetailsSelect: React.FC<EntityDetailsSelectProps> = ({
    ...rest
}) => {
    return (
        <PrimarySelect
            {...rest}
        >
            <option value="Self Help Group">Self Help Group</option>
            <option value="Cooperative">Cooperative</option>
            <option value="Association">Association</option>
        </PrimarySelect>
    )
}