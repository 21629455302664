import { createApi } from '@reduxjs/toolkit/query/react';
import { convertObjectToURLParams } from 'utilities/general';
import { axiosBaseQuery } from '../../utilities/axiosQuery/axiosBaseQuery';
import { baseUrl } from '../../utilities/requests';
import { IResponse } from '../auth/interface';
import { CreateEventPayload, PaginationResponse, DeleteEventPayload, EditEventPayload, FetchEventsPayload, ViewEventPayload } from './interface';


export const eventApi = createApi({
    reducerPath: 'eventApi',
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/` }),
    tagTypes: ['myProfile'],
    endpoints: (builder) => ({
        events: builder.query<PaginationResponse, FetchEventsPayload>({
            query: (payload) => ({
                url: `projects/events?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        event: builder.query<IResponse, ViewEventPayload>({
            query: (payload) => ({
                url: `projects/events/info?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        create: builder.mutation<IResponse, CreateEventPayload>({
            query: (payload) => ({
                url: 'projects/events/create',
                headers: { 'Content-Type': 'multipart/form-data'},
                method: 'POST',
                body: payload
            }),
        }),
        edit: builder.mutation<IResponse, EditEventPayload>({
            query: (payload) => ({
                url: 'projects/events/edit',
                method: 'POST',
                body: payload
            }),
        }),
        delete: builder.mutation<IResponse, DeleteEventPayload>({
            query: (payload) => ({
                url: 'projects/events/remove',
                method: 'POST',
                body: payload
            }),
        }),
    })
});

export const {
    useEventsQuery,
    useEventQuery,
    useCreateMutation,
    useEditMutation,
    useDeleteMutation,
} = eventApi;

