import { createApi } from '@reduxjs/toolkit/query/react';
import { convertObjectToURLParams } from 'utilities/general';
import { axiosBaseQuery } from '../../utilities/axiosQuery/axiosBaseQuery';
import { baseUrl } from '../../utilities/requests';
import { IResponse } from '../auth/interface';
import { SendEmailPayload, PaginationResponse, DeleteEmailPayload, FetchEmailsPayload, ViewEmailPayload } from './interface';


export const emailApi = createApi({
    reducerPath: 'emailApi',
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/` }),
    endpoints: (builder) => ({
        emails: builder.query<PaginationResponse, FetchEmailsPayload>({
            query: (payload) => ({
                url: `projects/mails?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        email: builder.query<IResponse, ViewEmailPayload>({
            query: (payload) => ({
                url: `projects/mails/info?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        send: builder.mutation<IResponse, SendEmailPayload>({
            query: (payload) => ({
                url: 'projects/mails/create',
                headers: { 'Content-Type': 'multipart/form-data'},
                method: 'POST',
                body: payload
            }),
        }),
        delete: builder.mutation<IResponse, DeleteEmailPayload>({
            query: (payload) => ({
                url: 'projects/mails/remove',
                method: 'POST',
                body: payload
            }),
        }),
    })
});

export const {
    useEmailsQuery,
    useEmailQuery,
    useSendMutation,
    useDeleteMutation,
} = emailApi;

