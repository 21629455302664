import { Button, useToast } from "@chakra-ui/react";
import { PrimaryTextarea, PrimaryInput } from "components";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useStoryQuery } from "store/stories";
import { ContentBodyContainer } from "../../home";
import { useLocation } from "react-router-dom";
import { useProject } from "store/projects";


export const ViewStory = () => {
  const navigate = useNavigate();
  const projectId: number = useProject().project?.id;
  const pathArray: string[] = useLocation().pathname.trim().split("/")
  const recordId = Number(pathArray[pathArray.length - 1])
  const { data, isLoading, refetch } = useStoryQuery({ project_id: projectId, record_id: recordId });
  const toast = useToast({ position: "top-right" });

  const editStoryRequest = () => {}


  return (
    <ContentBodyContainer
      title="View Story"
      routesRule={"view story"}
    >
        <div className="col-md-12 col-sm-12">
          <div className="card custom-card">
            <div className="px-3 pt-3 align-items-center d-flex border-bottom">
              {/* {leftCardHeaderComponent} */}
              <div className="mb-0 flex-grow-1 ">
                <p
                  className="fs-5 fw-bold"
                  style={{
                    color: "#2A4153",
                  }}
                >
                  Story - { data?.data?.title}
                </p>
              </div>
              <h4
                className="card-title mb-0 flex-grow-1 fw-bold"
                style={{
                  color: "#2A4153",
                }}
              // {...cardHeaderProps}
              >
                {/* {cardHeaderTitle} */}
              </h4>
              {/* {rightCardHeaderComponent} */}
            </div>
            <div className="mx-4 py-4">
              <div className="col-12 mb-4">
                <img 
                  src={data?.data?.uploads[0]?.url} 
                  className="img-fluid thumbnail" 
                  style={{objectFit: "cover", aspectRatio: "4/2"}}
                />
              </div>
              <div className="col-12 mb-4">
                <PrimaryInput
                  isRequired
                  name="title"
                  label="Story title"
                  placeholder="Enter story title"
                  value={data?.data?.title}
                  isDisabled={isLoading}
                  isReadOnly={true}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
            </div>

              <div className="col-12">
                <PrimaryTextarea
                  isRequired
                  name="description"
                  label={"Description"}
                  placeholder="Enter quick description of the story...."
                  size={"lg"}
                  rows={7}
                  value={data?.data?.description}
                  isReadOnly={true}
                  isDisabled={isLoading}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
    </ContentBodyContainer>
  );
};
