import { useToast } from "@chakra-ui/react";
import { PrimaryTextarea, PrimaryInput, PrimaryButton } from "components";
import { useNavigate } from "react-router-dom";
import { ContentBodyContainer } from "../../home";
import { useProject } from "store/projects";
import { useCreateMutation} from "store/event";
import { useFormik } from "formik";
import { CreateEventScheme } from "validations/event";
import { resolveApiError } from "utilities";

export const CreateEvent = () => {
  const toast = useToast({ position: "top-right" });
  const projectId: number = useProject().project?.id;
  const [request, { isLoading }] = useCreateMutation();
  const { values, errors, handleChange, handleSubmit, touched, resetForm } = useFormik({
    initialValues: {
      title: "", description: "", ends_at: "",
      starts_at: "", location: "", project_id: projectId
    },
    validationSchema: CreateEventScheme,
    onSubmit: () => initRequest(),
  });

  const initRequest = () => {
    request(values).unwrap().then((res) => {
      toast({ title: "Event", description: res?.response, status: "success" });
      resetForm({})
    }).catch((error) => {
        toast({ title: "Request Failed", description: resolveApiError(error), status: "error" });
    });
  }

  return (
    <ContentBodyContainer
      title="Create Event"
    >
       <div className="col-md-12 col-sm-12">
          <div className="card custom-card">
            <div className="px-3 pt-3 align-items-center d-flex border-bottom">
              {/* {leftCardHeaderComponent} */}
              <div className="mb-0 flex-grow-1 ">
                <p
                  className="fs-5 fw-bold"
                  style={{
                    color: "#2A4153",
                  }}
                >
                  Create an event
                </p>
              </div>
              <h4
                className="card-title mb-0 flex-grow-1 fw-bold"
                style={{
                  color: "#2A4153",
                }}
              // {...cardHeaderProps}
              >
                {/* {cardHeaderTitle} */}
              </h4>
              {/* {rightCardHeaderComponent} */}
            </div>
            <div className="mx-4 py-4">
              <div className="col-12 mb-4">
                <PrimaryInput
                  name="title"
                  label="Event title"
                  placeholder="Enter event title"
                  value={values?.title}
                  error={Boolean(errors.title && touched.title)}
                  bottomText={errors.title}
                  isDisabled={isLoading}
                  onChange={handleChange}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>

              <div className="col-12">
                <PrimaryTextarea
                  name="description"
                  label={"Description"}
                  placeholder="Enter description of the event...."
                  size={"lg"}
                  rows={7}
                  value={values?.description}
                  error={Boolean(errors.description && touched.description)}
                  bottomText={errors.description}
                  onChange={handleChange}
                  isDisabled={isLoading}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>

              <div className="row mx-1 my-4">
                <div className="col-md-6 col-sm-12">
                  <PrimaryInput
                    type="datetime-local"
                    min={new Date().toString()}
                    name="starts_at"
                    label="Start date"
                    value={values?.starts_at}
                    error={Boolean(errors.starts_at && touched.starts_at)}
                    bottomText={errors.starts_at}
                    isDisabled={isLoading}
                    onChange={handleChange}
                    style={{
                      backgroundColor: "#F2FAFC",
                      borderRadius: 0,
                      borderColor: "#CAECF3",
                    }}
                  />
                </div>

                <div className="col-md-6 col-sm-12">
                  <PrimaryInput
                    type="datetime-local"
                    name="ends_at"
                    min={new Date().toString()}
                    label="End date"
                    error={Boolean(errors.ends_at && touched.ends_at)}
                    bottomText={errors.ends_at}
                    isDisabled={isLoading}
                    onChange={handleChange}
                    style={{
                      backgroundColor: "#F2FAFC",
                      borderRadius: 0,
                      borderColor: "#CAECF3",
                    }}
                  />
                </div>
              </div>

              <div className="col-12 mb-4">
                <PrimaryInput
                  isRequired
                  type="text"
                  name="location"
                  label="Location"
                  value={values?.location}
                  error={Boolean(errors.location && touched.location)}
                  bottomText={errors.location}
                  isDisabled={isLoading}
                  onChange={handleChange}
                  style={{
                    backgroundColor: "#F2FAFC",
                    borderRadius: 0,
                    borderColor: "#CAECF3",
                  }}
                />
              </div>

              <div className="col-12 mb-4">
                <PrimaryButton
                  className="btn btn-primary mb-3"
                  type="submit"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                  style={{
                    padding: "16px, 48px, 16px, 48px",
                    borderRadius: 0,
                  }}
                >
                  Create
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
    </ContentBodyContainer>
  );
};
