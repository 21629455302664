import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Event } from '@store/event';

export const useEventsColumn = () => {
    return useMemo<MRT_ColumnDef<Event>[]>(
      () => [
        {
          header: "Title",
          accessorFn: (row) => `${row.title}`,
          Cell: ({ row }) => (
            <Link
              to={"/schedule/view/" + (row.original as Event).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(row.original.title)}
            </Link>
          ),
        },
        {
          header: "Description",
          accessorFn: (row) => `${ row.description.length ? row.description.substring(0, 30)+'...' : row.description}`,
        },
        {
          header: "Start Time",
          accessorFn: (row) => `${row.starts_at}`,
        },
        {
          header: "End Time",
          accessorFn: (row) => `${row.ends_at}`,
        },
        {
          header: "Location",
          accessorFn: (row) => `${ row.location }`,
        },
        {
          header: "Created By",
          accessorFn: (row) => `${row.created_by}`,
        }
      ],
      []
    );
};