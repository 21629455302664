import { FarmerInfo, Farmer } from 'store/farmers';
import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const useAllFarmersColumn = () => {
    return useMemo<MRT_ColumnDef<FarmerInfo>[]>(
      () => [
        {
          header: "First Name",
          accessorFn: (row) => `${row.first_name}`,
          Cell: ({ row }) => (
            <Link
              to={"/farmers/view/" + (row.original as Farmer).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(row.original.first_name)}
            </Link>
          ),
        },
        {
          header: "Last Name",
          accessorFn: (row) => `${row.last_name}`,
          Cell: ({ row }) => (
            <Link
              to={"/farmers/view/" + (row.original as Farmer).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(row.original.last_name)}
            </Link>
          ),
        },
        {
          header: "Gender",
          accessorFn: (row) => `${row.farmer_gender}`,
        },
        {
          header: "Address",
          accessorFn: (row) => `${row.farmer_address}`,
        },
        {
          header: "Phone Number",
          accessorFn: (row) => `${row.farmer_phone}`,
        },
        {
          header: "SHG Name",
          accessorFn: (row) => `${row.cluster_name}`,
        },
        {
          header: "Age",
          accessorFn: (row) => `${row.farmer_age}`,
        },
        {
          header: "Age Category",
          accessorFn: (row) => `${row.farmer_age_category}`,
        },
        {
          header: "Household Head's Gender",
          accessorFn: (row) => `${row.house_head_gender}`,
        },
        {
          header: "Education",
          accessorFn: (row) => `${row.house_head_edu}`,
        },
        {
          header: "Marital Status",
          accessorFn: (row) => `${row.marital_status}`,
        },
        {
          header: "Valid ID",
          accessorFn: (row) => `${row.valid_id}`,
        },
        {
          header: "State",
          accessorFn: (row) => `${row.state}`,
        },
        {
          header: "LGA",
          accessorFn: (row) => `${row.lga}`,
        },
        {
          header: "Community",
          accessorFn: (row) => `${row.community}`,
        },
        {
          header: "Group Name",
          accessorFn: (row) => `${row.group_name}`,
        },
        {
          header: "Group Type",
          accessorFn: (row) => `${row.group_type}`,
        },
        {
          header: "Latitude",
          accessorFn: (row) => `${row.latitude}`,
        },
        {
          header: "Longitude",
          accessorFn: (row) => `${row.longitude}`,
        },
        {
          header: "Males",
          accessorFn: (row) => `${row.no_of_males}`,
        },
        {
          header: "Females",
          accessorFn: (row) => `${row.no_of_females}`,
        },
        {
          header: "Total Households",
          accessorFn: (row) => `${row.total_household}`,
        },
        {
          header: "Farm Size",
          accessorFn: (row) => `${row.farm_size}`,
        },
        {
          header: "Household Type",
          accessorFn: (row) => `${row.household_type}`,
        },
        {
          header: "Household Income",
          accessorFn: (row) => `${row.household_income}`,
        },
        {
          header: "Date Added",
          accessorFn: (row) => `${moment(row.created_at).format('MMMM Do, YYYY')}`,
        },
      ],
      []
    );
};