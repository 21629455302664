import { createApi } from '@reduxjs/toolkit/query/react';
import { convertObjectToURLParams } from 'utilities/general';
import { axiosBaseQuery } from '../../utilities/axiosQuery/axiosBaseQuery';
import { baseUrl } from '../../utilities/requests';
import { IResponse } from '../auth/interface';
import { AddIndicatorPayload, ChurchIndicatorsResponse, FetchIndicatorsPayload, IndicatorSuccessResponse, DeleteIndicatorsPayload  } from './interface';


export const indicatorApi = createApi({
    reducerPath: 'indicatorApi',
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/` }),
    tagTypes: ['myProfile'],
    endpoints: (builder) => ({
        getIndicators: builder.query<ChurchIndicatorsResponse, FetchIndicatorsPayload>({
            query: (payload) => ({
                url: `projects/indicators?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        fetchIndicators: builder.mutation<ChurchIndicatorsResponse, FetchIndicatorsPayload>({
            query: (payload) => ({
                url: `projects/indicators?${convertObjectToURLParams(payload)}`,
                method: 'GET',
                body: payload
            }),
        }),
        addIndicator: builder.mutation<IndicatorSuccessResponse, AddIndicatorPayload>({
            query: (payload) => ({
                url: 'projects/indicators/create',
                method: 'POST',
                body: payload
            }),
        }),
        getIndicatorInfo: builder.query<IResponse, { project_id: number, Indicator_id: number|string }>({
            query: (payload) => ({
                url: `projects/indicators/info?${convertObjectToURLParams(payload)}`,
                method: 'GET',
            }),
        }),
        deleteIndicator: builder.mutation<IResponse, DeleteIndicatorsPayload>({
            query: (payload) => ({
                url: 'projects/indicators/remove',
                method: 'POST',
                body: payload
            }),
        }),
    })
});

export const {
    useGetIndicatorsQuery,
    useFetchIndicatorsMutation,
    useAddIndicatorMutation,
    useDeleteIndicatorMutation,
    useGetIndicatorInfoQuery,
} = indicatorApi;

