import { PrimarySelect, PrimarySelectProp, PrimaryInput } from "components";
import { useState } from 'react';

interface TargetGroupSelectProps extends PrimarySelectProp {}

export const TargetGroupSelect: React.FC<TargetGroupSelectProps> = ({ ...rest }) => {
    const [newValue, setNewValue] = useState('');
  
    const handleNewValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewValue(event.target.value);
    };
  return (
    <>
    <PrimarySelect
      label="Target Group"
      placeholder="Select your answer..."
      {...rest}
    >
      <option value="Project participants">Project participants</option>
      <option value="Community Facilitators">Community Facilitators</option>
      <option value="Stakeholders">Stakeholders</option>
      <option value="Community members">Community members</option>
      <option value="others">Others, Specify</option> {/* Updated value */}
    </PrimarySelect>
    {rest.value === 'others' && ( /* Updated condition */
        <PrimaryInput className="mt-4"
          type="text"
          value={newValue}
          onChange={handleNewValueChange}
          placeholder="Please specify..."
          style={{
            backgroundColor: "#F2FAFC",
            borderRadius: 0,
            borderColor: "#CAECF3",
          }}
        />
      )}
    </>
  );
};
