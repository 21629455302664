import { textCapitalize } from "utilities"

const NigeriaStates = [
    { "state": { "name": "Benue State", "id" :1, "locals": [
        { "name": "Agatu", "id" :1, "communities": [  { "name": "Adogoji" },
        { "name": "Geche" },
        { "name": "Otukpo-Road" },
        { "name": "Ologba" },
        { "name": "Oshigbudu" },
        { "name": "Ekeida" },
        { "name": "Ologbagishu" },
        { "name": "Olegoga" },
        { "name": "Alokpa" },
        { "name": "Usha" },
        { "name": "Ello" },
        { "name": "Ogbangede" },
        { "name": "Ikpele" },
        { "name": "Okpagabi" },
        { "name": "Ekwo" },
        { "name": "Ogam" },
        { "name": "Oweto" },
        { "name": "Ogufa" },
        { "name": "Enungba" },
        { "name": "Okpancheni" },
        { "name": "Ogwumogbo" },
        { "name": "Warri" },
        { "name": "Okpokpolo-Ichogolugwu" },
        { "name": "Enicha" },
        { "name": "Egila" },
        { "name": "Oleginaikwu" },
        { "name": "Olegidaka" },
        { "name": "Alia" },
        { "name": "Okokolo" },
        { "name": "Egba" },
        { "name": "Adagbo" },
        { "name": "Odugbeho" },
        { "name": "Akwu" },
        { "name": "Abugbe" },
        { "name": "Adana" }] },
        
        { "name": "Guma", "id" :2, "communities": [
            { "name": "Iye" },
            { "name": "Imande Dem" },
            { "name": "Tse Orvihi" },
            { "name": "Zwayongu" },
            { "name": "Antso" },
            { "name": "Tse Adum" },
            { "name": "Pakena" },
            { "name": "Ortim" },
            { "name": "Antsar" },
            { "name": "Ukaa-Kaseyo" },
            { "name": "Daudu" },
            { "name": "Umenger" },
            { "name": "Ukpiam" },
            { "name": "Tyohembe" },
            { "name": "Tseyam" },
            { "name": "Tse-Dei" },
            { "name": "Ayem" },
            { "name": "Ukange" },
            { "name": "Ortaver" },
            { "name": "Agbaka" },
            { "name": "Upev" },
            { "name": "Logo" },
            { "name": "Gbajimba" },
            { "name": "Tomatar" },
            { "name": "Ortese" },
            { "name": "Yogbo" },
            { "name": "Ukohol" },
            { "name": "Hirnyam" },
            { "name": "Kwaghshir" },
            { "name": "Agasha" },
            { "name": "Azon" },
            { "name": "Kwata" }] },
        { "name": "Gwer East", "id" :3, "communities": [ { "name": "Mbanyon" },
        { "name": "Mnachilla" },
        { "name": "Mbanyanagwa" },
        { "name": "UTyomase" },
        { "name": "Mbamiase" },
        { "name": "Mbapier" },
        { "name": "Mbamar" },
        { "name": "Mbaapen" },
        { "name": "Mbaba" },
        { "name": "Taraku" },
        { "name": "Mbaweghgba" },
        { "name": "Mbada holiday" },
        { "name": "Mbadenga" },
        { "name": "Mbaba nyam" },
        { "name": "Mbaasaragba" },
        { "name": "mbadinya" },
        { "name": "Mbaior" },
        { "name": "OnmbaGwe" },
        { "name": "Utonko" },
        { "name": "Mbagam" },
        { "name": "Ugondo" },
        { "name": "Mbada" },
        { "name": "Mbakough" },
        { "name": "Nyamkaan" },
        { "name": "Mbashir" },
        { "name": "Mbada" },
        { "name": "Ainu" },
        { "name": "Ogboba" },
        { "name": "Akpachayi central" },
        { "name": "Ukedenyi" },
        { "name": "Ojumole" },
        { "name": "Mbakya" },
        { "name": "Agayo/Abese" },
        { "name": "Mbandyer" },
        { "name": "Mbadera" },
        { "name": "Mbakohol" },
        { "name": "Mbaga" },
        { "name": "Mbayongo" },
        { "name": "Mbachor" },
        { "name": "Mbamune" },
        { "name": "Mbaakuna" },
        { "name": "Mbakough" },
        { "name": "Mbakose" },
        { "name": "Mbatsanger" },
        { "name": "Aliade/Nenge" },
        { "name": "Mbazu" },
        { "name": "Mbakyambe" },
        { "name": "Mbaya" },
        { "name": "Mnaamende" },
        { "name": "Mbaiwar" },
        { "name": "Mbamar" },
        { "name": "Mbagba" },
        { "name": "Mbakough" },
        { "name": "Mbaagir" },
        { "name": "Mbaaku" },
        { "name": "Mbatsavkar" },
        { "name": "Mbakera" },
        { "name": "Mbakume" },
        { "name": "Mbakaha" },
        { "name": "Mbazu" },
        { "name": "Mbagben" },
        { "name": "Mbakese" },
        { "name": "Mbasa" },
        { "name": "Mbatyulla" },
        { "name": "Mbagoragba" },
        { "name": "Mbagyer" },
        { "name": "Mbagooso" },
        { "name": "Mbatsada" },
        { "name": "Mbakyor" },
        { "name": "Uburuku" }] },

        { "name": "Gwer West", "id" :4, "communities": [{ "name": "Tse Lafu" },
        { "name": "Tse Angban" },
        { "name": "Tse Defam" },
        { "name": "Tse Mkovul" },
        { "name": "Gbaagbera" },
        { "name": "Tse Ugbo-Adugu" },
        { "name": "Branch Ikpe" },
        { "name": "Tse Atondiir" },
        { "name": "Mbashie" },
        { "name": "Agbakwa" },
        { "name": "Tse Tarkaa" },
        { "name": "Atswerga" },
        { "name": "Bunaka" },
        { "name": "Kperyisa" },
        { "name": "Tse Adudu" },
        { "name": "Tse Agye" },
        { "name": "Tse Mtsem" },
        { "name": "Teman" },
        { "name": "Anguhar" },
        { "name": "Tse Anyon" },
        { "name": "Tse Zwatema" },
        { "name": "Tse Ukuma" },
        { "name": "Tse Jibili" },
        { "name": "Tse Ugbegili" },
        { "name": "Tse Uta" },
        { "name": "Girgir" },
        { "name": "Tse Gesa" },
        { "name": "Tse Nyamti" },
        { "name": "Tse Gamber" },
        { "name": "Tse Aguji" },
        { "name": "Tse Kyerkyaa" },
        { "name": "Tse Achoho" },
        { "name": "Tse Ugbulu" },
        { "name": "Tse Chuada" },
        { "name": "Tse Namikpe" },
        { "name": "Tse Shaapera" },
        { "name": "Tse Adagba" },
        { "name": "Tse Kov" },
        { "name": "Tse Gbakule" },
        { "name": "Tse Akundu-Tyough" },
        { "name": "Tse Alaigyu" },
        { "name": "Tse Bodanyi" }] },

        { "name": "Kwande", "id" :5, "communities": [{ "name": "Mbayoo" },
        { "name": "Mbakwen" },
        { "name": "Usar" },
        { "name": "Mbawer" },
        { "name": "Mbaiwen" },
        { "name": "Mbaikyan-Mbawer" },
        { "name": "Mbakyan-Amile" },
        { "name": "Mbayue" },
        { "name": "Mbalumun" },
        { "name": "Ihonam" },
        { "name": "Kohov" },
        { "name": "Gube" },
        { "name": "Mbakuha" },
        { "name": "Uyough" },
        { "name": "Mbaikyan-Bebe" },
        { "name": "Abenga" },
        { "name": "Newsite" },
        { "name": "Kookoko" },
        { "name": "Mbaatsagher" },
        { "name": "Ajio Orbo-Mbanghol" },
        { "name": "Koti-Mbaduku" },
        { "name": "Sati-Area" },
        { "name": "Mbakunu" },
        { "name": "Iyon" },
        { "name": "Mbahura" },
        { "name": "Mbakan-Aayongo" },
        { "name": "Tica-Mbaper" },
        { "name": "Koblo" },
        { "name": "Kohov-Gom Gire" },
        { "name": "Mbape" },
        { "name": "Adagi" },
        { "name": "Tse Kur" },
        { "name": "Tse-Ahobee" },
        { "name": "Achia" },
        { "name": "Mbachile" },
        { "name": "Mbaza Ikyogen" },
        { "name": "Ugbamuji" },
        { "name": "Tyogbenda" },
        { "name": "Ujir" },
        { "name": "Mbahini" },
        { "name": "Igyom" },
        { "name": "Annune" },
        { "name": "Mbaagwa" },
        { "name": "Mbaza" },
        { "name": "Ahondo" },
        { "name": "Kendev" },
        { "name": "Anwase" },
        { "name": "Mbaav" },
        { "name": "Tomataan mbawarku" },
        { "name": "Mbachom" },
        { "name": "Tomataan mbashagher" },
        { "name": "Menakwagh" },
        { "name": "Ingyenev" },
        { "name": "kumakwagh" },
        { "name": "Abande" },
        { "name": "Udeadeku" },
        { "name": "Ichor-Nyiev" },
        { "name": "Boagundu" },
        { "name": "Ayaga" },
        { "name": "Jato-Aka" },
        { "name": "Nzaav" },
        { "name": "Mbako" },
        { "name": "Nyihemba" },
        { "name": "Iniongov" },
        { "name": "Agula" },
        { "name": "Ichembe" },
        { "name": "Injorov" }] },

        { "name": "Logo", "id" :6, "communities": [{ "name": "Abeda-Mbadyul" },
        { "name": "Aganyi" },
        { "name": "Ugba" },
        { "name": "Aganyi" },
        { "name": "Amatso" },
        { "name": "Anbii" },
        { "name": "Asom" },
        { "name": "Gondozua" },
        { "name": "Itine-Ikura" },
        { "name": "Tse Abuul" },
        { "name": "Tse Kyausu" },
        { "name": "Kave" },
        { "name": "Tse Ggw" },
        { "name": "Tse Anko" },
        { "name": "Igbon" },
        { "name": "Jiji" },
        { "name": "Ikyor" },
        { "name": "Tse Aluor" },
        { "name": "Tse Aduku" },
        { "name": "Tse Nenge" },
        { "name": "Diba" },
        { "name": "Tse Abanya" },
        { "name": "Tse Ityokaa" },
        { "name": "Tse Chidam" },
        { "name": "Tse Ikyegh" },
        { "name": "Gbanyam" },
        { "name": "Akaa Sakera" },
        { "name": "Wende" },
        { "name": "Iorshaher" },
        { "name": "Tse Agbungu" },
        { "name": "Tse Akaa" },
        { "name": "Zanzan" },
        { "name": "Agasar" },
        { "name": "Abiem" },
        { "name": "Atetan" },
        { "name": "Oralu" },
        { "name": "Kajiva" },
        { "name": "Tse Ikyegh" },
        { "name": "Abeda" },
        { "name": "Doorshima" },
        { "name": "Igbouhul" },
        { "name": "Dusa" },
        { "name": "Biki" },
        { "name": "Ugo" },
        { "name": "June" },
        { "name": "Tse Girgi" },
        { "name": "Orwua" },
        { "name": "Shenge" },
        { "name": "Tse Orkighir" },
        { "name": "Jootar" },
        { "name": "Anyiin" },
        { "name": "Iorza" },
        { "name": "Mchia" },
        { "name": "Mou" },
        { "name": "Chembe" },
        { "name": "Toatar" },
        { "name": "Adawa" },
        { "name": "Tse Igbur" },
        { "name": "Meeme" },
        { "name": "Ayilamo" },
        { "name": "Anyimbe" },
        { "name": "Azege" },
        { "name": "Tse-Kurungu" },
        { "name": "User" },
        { "name": "Mbakighir" },] },

        { "name": "Makurdi", "id" :7, "communities": [
            { "name": "Mbyongo" },
            { "name": "Orawa" },
            { "name": "Agee" },
            { "name": "Nyiev" },
            { "name": "Mbakyor" },
            { "name": "Uniagri" },
            { "name": "Mbagwa" },
            { "name": "Abua-Center" },
            { "name": "Abua" },
            { "name": "Akile-Mu" },
            { "name": "Nyiv" },
            { "name": "Agandi" },
            { "name": "Mbagwen" },
            { "name": "Uchonu" },
            { "name": "Ornyiev" },
            { "name": "Yagba" },
            { "name": "Jov-Father" },
            { "name": "Agbaana-Jamu" },
            { "name": "Igbudu Agagbe" },
            { "name": "Agboughul" },
            { "name": "Waar" },
            { "name": "Ute layout" },
            { "name": "Ikyoayaba" },
            { "name": "Anwav" },
            { "name": "Adaka" },
            { "name": "mgbeta" },
            { "name": "mbazemba" },
            { "name": "Ucheen" },
            { "name": "Anune" },
            { "name": "Nyiti" },
            { "name": "Mbagbam" },
            { "name": "Mbaya" },
            { "name": "Mbawen/Mgbeta" },
            { "name": "Achien" },
            { "name": "Niongun-Lower Base" },
            { "name": "Government College" },
            { "name": "Industrial Layout" }] },
        { "name": "Okpokwu", "id" :8, "communities": [{ "name": "Ojapo" },
  { "name": "Igama" },
  { "name": "Akpodo" },
  { "name": "Okpale" },
  { "name": "Olaidu" },
  { "name": "Ojigo" },
  { "name": "Ogomotu" },
  { "name": "Amufu- ipole" },
  { "name": "Amufu-Ame" },
  { "name": "Amufu-Ai- ogbanje" },
  { "name": "Effiom" },
  { "name": "Ipoya" },
  { "name": "Ijeha" },
  { "name": "Ejema" },
  { "name": "Okpolikpo" },
  { "name": "Okpliho" },
  { "name": "Otobi" },
  { "name": "Opialu" },
  { "name": "Amufu" },
  { "name": "Ai-Ede" },
  { "name": "Okana" },
  { "name": "Ogomotu" },
  { "name": "Ai-Ameh" },
  { "name": "Agbangwe" },
  { "name": "Okpudu" },
  { "name": "Idiri" },
  { "name": "Oklenyi" },
  { "name": "Adiga" },
  { "name": "Ichama-Center" },
  { "name": "Ojoga" },
  { "name": "Oma" },
  { "name": "Ameju" },
  { "name": "Ollo" },
  { "name": "Eboya" },
  { "name": "Iwewe" },
  { "name": "Okodo" },
  { "name": "Egedegbe" },
  { "name": "Efoyo" },
  { "name": "Ai-Akor" },
  { "name": "Efa" },
  { "name": "Ichakwu" },
  { "name": "Ogbodo" },
  { "name": "Ai-Okpe" },
  { "name": "Ai-Idoko Emu" },
  { "name": "Olago" },
  { "name": "Ondo" },
  { "name": "Okpudo" },
  { "name": "Ekwu" },
  { "name": "Ajide" },
  { "name": "Okpafie" },
  { "name": "Agamudu" },
  { "name": "Efaofo" },
  { "name": "Ohuloko" },
  { "name": "Ipala" },
  { "name": "Ai-Uja" },
  { "name": "Ai-Adowu" },
  { "name": "Odeito" },
  { "name": "Elugu Mission" }] },
        ] }

    },
    { "state": { "name": "Lagos State", "id" :2, "locals": [
        { "name": "Badagry", "id" :1, "communities": [
            { "name": "Ibereko" },
            { "name": "Oke oko" },
            { "name": "Ajara" },
            { "name": "Ajido" },
            { "name": "Akarakumo" },
            { "name": "Gbaji" }
          ]
    },
        { "name": "Epe", "id" :2, "communities": [{ "name": "Ogolonto"},{ "name": "Oke Oyinba"}] },
        { "name": "Ikorodu", "id" :3, "communities": [
            { "name": "Ugondo" },
            { "name": "Ugba" },
            { "name": "Abe Abo" },
            { "name": "Abule Ajeregun" },
            { "name": "Abule Igbira" },
            { "name": "Agodo Nla" },
            { "name": "Aleke" },
            { "name": "Araromi Ikorodu" },
            { "name": "Apeje" },
            { "name": "Baiyeku" },
            { "name": "Agunfoye/Igbogbo" },
            { "name": "Abule Eko" },
            { "name": "Fadahin" },
            { "name": "Gbagbowa" }
          ]
           }] }
    },
    { "state": { "name": "Nasarawa State", "id" :3, "locals": [
        { "name":  "Awe", "id" :1, "communities": [{ "name": "Akiri"},{ "name": "Azara"},{ "name": "Galadima"},{ "name": "Jangaru"},{ "name": "Kanje/abuni"},{ "name": "Madaki"},{ "name": "Makwangiji"},{ "name": "Ribi"},{ "name": "Tungu"},{ "name": "Wuse"}] },
        { "name": "Doma", "id" :2, "communities": [{ "name": "Agbashi"},{ "name": "Akpanaja"},{ "name": "Alagye I"},{ "name": "Alagye II"},{ "name": "Doka I"},{ "name": "Doka II"},{ "name": "Rukubi I"},{ "name": "Rukubi II"},{ "name": "Sarkin Dawaki"},{ "name": "Ungwan Madaki"}] },
        { "name": "Karu", "id" :3, "communities": [{ "name": "Agada/bagaji"},{ "name": "Aso/Kodape"},{ "name": "Gitata"},{ "name": "Gurku/kabusa"},{ "name": "Kafin Shana/betti"},{ "name": "Karshi I"},{ "name": "Karshi II"},{ "name": "Karu"},{ "name": "Panda/Kare"},{ "name": "Tattara/kondoro"},{ "name": "Uke"}] },
        { "name": "Keana", "id" :4, "communities": [{ "name": "Agaza"},{ "name": "Aloshi"},{ "name": "Amiri"},{ "name": "Giza Galadima"},{ "name": "Giza Madaki"},{ "name": "Iwagu"},{ "name": "Kadarto"},{ "name": "Kwara"},{ "name": "Obene"},{ "name": "Oki"}] },
        { "name": "Keffi", "id" :5, "communities": [{ "name": "Ang.rimi"},{ "name": "Angwan Iya I"},{ "name": "Angwan Iya II"},{ "name": "Gangare Tudu"},{ "name": "Jigwada"},{ "name": "Keffi Town East / Kofar Goriya"},{ "name": "Liman Abaji"},{ "name": "Sabon Gari"},{ "name": "Tuun Kofa T.v"},{ "name": "Yara"}] },
        { "name": "Kokona", "id" :6, "communities": [{ "name": "Agwada"},{ "name": "Amba"},{ "name": "Bassa"},{ "name": "Dari"},{ "name": "Garaku"},{ "name": "Hadari"},{ "name": "Kofar Gwari"},{ "name": "Kokona"},{ "name": "Koya / Kana"},{ "name": "Ninkoro"},{ "name": "Yelwa"}] },
        { "name": "Lafia", "id" :7, "communities": [{ "name": "Adogi"},{ "name": "Agyaragun Tofa"},{ "name": "Arikya"},{ "name": "Ashigie"},{ "name": "Assakio"},{ "name": "Bakin Rijiya/akurba/ sarkin pada"},{ "name": "Chiroma"},{ "name": "Gayam"},{ "name": "Keffin/wambai"},{ "name": "Makama"},{ "name": "Shabu/kwandere"},{ "name": "Wakwa"},{ "name": "Zanwa"}] },
        { "name": "Nasarawa", "id" :8, "communities": [{ "name": "Akum"},{ "name": "Ara I"},{ "name": "Ara II"},{ "name": "Guto/aisa"},{ "name": "Kanah/onda/apawu"},{ "name": "Laminga"},{ "name": "Loko"},{ "name": "Nasarawa Central"},{ "name": "Nasarawa East"},{ "name": "Nasarawa Main Town"},{ "name": "Nasarawa North"},{ "name": "Tunga/bakono"},{ "name": "Odu"},{ "name": "Udenin"},{ "name": "Udenin Gida"}] },
        { "name": "Nasarawa Egon", "id" :9, "communities": [{ "name": "Agunji"},{ "name": "Aloce/ginda"},{ "name": "Alogani"},{ "name": "Enda"},{ "name": "Igga/burumburum"},{ "name": "Ikka Wangibi"},{ "name": "Kagbu Wana"},{ "name": "Lambaga / arikpa"},{ "name": "Lizzin Keefi/ ezzen"},{ "name": "Mada Station"},{ "name": "Nasarawa Eggon"},{ "name": "Ubbe"},{ "name": "Umme"},{ "name": "Wakama"}] },
        { "name": "Obi", "id" :10, "communities": [{ "name": "Adiko"},{ "name": "Adum West"},{ "name": "Ikwokwu"},{ "name": "Irabi"},{ "name": "Itogo"},{ "name": "Obarike"},{ "name": "Obeko"},{ "name": "Odiapa"},{ "name": "Ogore"},{ "name": "Okpokwu"},{ "name": "Okwuntungbe"},{ "name": "Orihi"}] },
        { "name": "Toto", "id" :11, "communities": [{ "name": "Bugakarmo"},{ "name": "Dausu"},{ "name": "Gadagwa"},{ "name": "Gwargwada"},{ "name": "Kenyehu"},{ "name": "Kenyehu I"},{ "name": "Shafan Abakpa I"},{ "name": "Shafan Kwatto I"},{ "name": "Shege I"},{ "name": "Toto"},{ "name": "Ugya"},{ "name": "Umaisha"}] },
        { "name": "Wamba", "id" :12, "communities": [{ "name": "Arum"},{ "name": "Gitta"},{ "name": "Jimiya"},{ "name": "Konvah"},{ "name": "Kwara"},{ "name": "Mangar"},{ "name": "Nakere"},{ "name": "Wamba East"},{ "name": "Wamba West"},{ "name": "Wayo"}] }] }
    },
    { "state" : { "name": "Edo State", "id" :4, "locals": [
        { "name": "Akoko-Edo", "id" :1, "communities": [{ "name": "Enwan/atte/ikpeshi/egbigele"},{ "name": "Ibillo/Ekpesa/Ekor/Ikiran-ile/Oke"},{ "name": "Igarra I"},{ "name": "Igarra II"},{ "name": "Imoga/Lampese/Bekuma/Ekpe"},{ "name": "Makeke/Ojah/Damgbala/Ojirami/Anyawoza"},{ "name": "Oloma/Okpa/Ijaja/Kakuma/Anyara"},{ "name": "Ososo"},{ "name": "Somorika/Ogbe/Sasaro/Onumu/Eshawa/Ogugu Igboshi-afe/Igboshi-Ele/Aiyegunle"},{ "name": "Uneme-nekhua/akpama/Aiyetoro/Ekpedo/Erhurun/Uneme Osu"}] },
        { "name": "Egor", "id" :2, "communities": [{ "name": "Egor"},{ "name": "Evbareke"},{ "name": "Ogida/use"},{ "name": "Okhoro"},{ "name": "Oliha"},{ "name": "Otubu"},{ "name": "Ugbowo"},{ "name": "Uselu I"},{ "name": "Uselu II"},{ "name": "Uwelu"}] },
        { "name": "Etsako East", "id" :3, "communities": [{ "name": "Agenebode"},{ "name": "Okpekpe"},{ "name": "Okpella I"},{ "name": "Okpella II"},{ "name": "Okpella III"},{ "name": "Okpella IV"},{ "name": "Three Ibies"},{ "name": "Wanno I"},{ "name": "Wanno II"},{ "name": "Weppa"}] },
        { "name": "Etsako West", "id" :4, "communities": [{ "name": "Anwain"},{ "name": "Auchi"},{ "name": "Auchi I"},{ "name": "Auchi II"},{ "name": "Auchi III"},{ "name": "Aviele"},{ "name": "Jagbe"},{ "name": "South Ibie"},{ "name": "Uzairue North East"},{ "name": "Uzairue North West"},{ "name": "Uzairue South East"},{ "name": "Uzairue South West"}] },
        { "name": "Igueben", "id" :5, "communities": [{ "name": "Afuda/idumuoka"},{ "name": "Amahor/ugun"},{ "name": "Ekekhen/idumu/ogo/egbike"},{ "name": "Ekpon"},{ "name": "Ewossa"},{ "name": "Idigun/idumedo"},{ "name": "Okalo/Okpujie"},{ "name": "Owu/Okuta/Eguare Ebelle"},{ "name": "Udo"},{ "name": "Uhe/idumuogbo/idumueke"}] },
        { "name": "Ikpoba-Okha", "id" :6, "communities": [{ "name": "Aduwawa/Evbo Modu"},{ "name": "Gorretti"},{ "name": "Idogbo"},{ "name": "Iwogban/Uteh"},{ "name": "Obayantor"},{ "name": "Ogbeson"},{ "name": "Ologbo"},{ "name": "Oregbeni"},{ "name": "St. Saviour"},{ "name": "Ugbekun"}] },
        { "name": "Oredo", "id" :7, "communities": [{ "name": "Gra/Etete"},{ "name": "Ibiwe/Iwegie/Ugbague"},{ "name": "Ihogbe/Isekhere/Oreoghene/Ibiwa/Ice Road"},{ "name": "Ikpema/Eguadase"},{ "name": "New Benin I"},{ "name": "New Benin II"},{ "name": "Ogbe"},{ "name": "Ogbelaka/Nekpenekpen"},{ "name": "Oredo"},{ "name": "Unueru/Ogboka"},{ "name": "Urubi/Evbiemwen/Iwehen"},{ "name": "Uzebu"}] },
        { "name": "Orhionmwon", "id" :8, "communities": [{ "name": "Aibiokula I"},{ "name": "Aibiokula II"},{ "name": "Evboesi"},{ "name": "Igbanke East"},{ "name": "Igbanke West"},{ "name": "Iyoba"},{ "name": "Ugbeka"},{ "name": "Ugboko"},{ "name": "Ugu"},{ "name": "Ukpato"},{ "name": "Urhonigbe North"},{ "name": "Urhonigbe South"}] },
        { "name": "Ovia North-East", "id" :9, "communities": [{ "name": "Adolor"},{ "name": "Iguoshodin"},{ "name": "Isiuwa"},{ "name": "Oduna"},{ "name": "Ofunmwegbe"},{ "name": "Oghede"},{ "name": "Okada East"},{ "name": "Okada West"},{ "name": "Okokhuo"},{ "name": "Oluku"},{ "name": "Uhen"},{ "name": "Uhiere"},{ "name": "Utoka"}] },
        { "name": "Ovia South-West", "id" :10, "communities": [{ "name": "Iguobazuwa East"},{ "name": "Iguobazuwa West"},{ "name": "Nikorogha"},{ "name": "Ofunama"},{ "name": "Ora"},{ "name": "Siluko"},{ "name": "Udo"},{ "name": "Ugbogui"},{ "name": "Umaza"},{ "name": "Usen"}] },
        { "name": "Owan East", "id" :11, "communities": [{ "name": "Emai I"},{ "name": "Emai II"},{ "name": "Igue/Ikao"},{ "name": "Ihievbe I"},{ "name": "Ihievbe II"},{ "name": "Ivbiadaobi"},{ "name": "Otuo I"},{ "name": "Otuo II"},{ "name": "Uokha/Ake"},{ "name": "Warrake"},{ "name": "Ivbiaion"}] },
        { "name": "Owan West", "id" :12, "communities": [{ "name": "Avbiosi"},{ "name": "Eme-ora/Oke"},{ "name": "Eruere"},{ "name": "Okpuje"},{ "name": "Ozalla"},{ "name": "Sabongida/Ora/Ogbeturu"},{ "name": "Sobe"},{ "name": "Uhonmara"},{ "name": "Ukhuse-osi"},{ "name": "Uzebba I"},{ "name": "Uzebba II"}] },
        { "name": "Uhunmwonde", "id" :13, "communities": [{ "name": "Egbede"},{ "name": "Ehor"},{ "name": "Igieduma"},{ "name": "Irhue"},{ "name": "Isi North"},{ "name": "Isi South"},{ "name": "Ohuan"},{ "name": "Uhi"},{ "name": "Umagbae North"},{ "name": "Umagbae South"}] }] }
    },
    { "state" : { "name": "Ogun State", "id" :5, "locals": [
        { "name": "Abeokuta North", "id" :1, "communities": [{ "name": "Abeokuta"},{ "name": "Oke-Ilewo"},{ "name": "Lafenwa"},{ "name": "Kuto"},{ "name": "Ake"}] },
        { "name": "Abeokuta South", "id" :2, "communities": [{ "name": "Ita-Eko"},{ "name": "Kuto"},{ "name": "Ake"},{ "name": "Ijeun-Titun"},{ "name": "Oke-Itoku"}] },
        { "name": "Ado-Odo/Ota", "id" :3, "communities": [{ "name": "Sango-Ota"},{ "name": "Agbara"},{ "name": "Ijoko"},{ "name": "Atan"},{ "name": "Owode"}] },
        { "name": "Egbado North", "id" :4, "communities": [{ "name": "Ilaro"},{ "name": "Oja-Odan"},{ "name": "Imasai"},{ "name": "Idogo"},{ "name": "Ijaka"}] },
        { "name": "Egbado South", "id" :5, "communities": [{ "name": "Ijebu-Imusin"},{ "name": "Owode"},{ "name": "Imagbon"},{ "name": "Ode-Remo"},{ "name": "Ilaporu"}] },
        { "name": "Ewekoro", "id" :6, "communities": [{ "name": "Itori"},{ "name": "Papalanto"},{ "name": "Mowe"},{ "name": "Agbado"},{ "name": "Obada-Oko"}] },
        { "name": "Ifo", "id" :7, "communities": [{ "name": "Sango"},{ "name": "Iju"},{ "name": "Agbado"},{ "name": "Ajuwon"},{ "name": "Ijoko"}] },
        { "name": "Ijebu East", "id" :8, "communities": [{ "name": "Ogbere"},{ "name": "Awa"},{ "name": "Atan"},{ "name": "Idowa"},{ "name": "Ore"}] },
        { "name": "Ijebu North", "id" :9, "communities": [{ "name": "Ijebu-Igbo"},{ "name": "Atan"},{ "name": "Oru"},{ "name": "Imodi"},{ "name": "Aiyepe"}] },
        { "name": "Ijebu North East", "id" :10, "communities": [{ "name": "Oke-Agbo"},{ "name": "Itele"},{ "name": "Ijebu-Ife"},{ "name": "Omu"},{ "name": "Imobi"}] },
        { "name": "Ijebu Ode", "id" :11, "communities": [{ "name": "Ijebu-Ode"},{ "name": "Imepe"},{ "name": "Odo-Egbo"},{ "name": "Ogbagba"},{ "name": "Isonyin"}] },
        { "name": "Ikenne", "id" :12, "communities": [{ "name": "Iperu"},{ "name": "Remo"},{ "name": "Ilisan"},{ "name": "Irolu"},{ "name": "Ilara"}] },
        { "name": "Imeko Afon", "id" :13, "communities": [{ "name": "Imeko"},{ "name": "Abeokuta Meje"},{ "name": "Ipokia"},{ "name": "Ketu"},{ "name": "Oke-Ayo"}] },
        { "name": "Ipokia", "id" :14, "communities": [{ "name": "Idi-Iroko"},{ "name": "Ipokia Town"},{ "name": "Ajegunle"},{ "name": "Alapoti"},{ "name": "Agosasa"}] },
        { "name": "Obafemi Owode", "id" :15, "communities": [{ "name": "Owode"},{ "name": "Ibafo"},{ "name": "Arepo"},{ "name": "Magboro"},{ "name": "Olowotedo"}] },
        { "name": "Odeda", "id" :16, "communities": [{ "name": "Odeda"},{ "name": "Lafenwa"},{ "name": "Adigbe"},{ "name": "Ogo-Oluwa"},{ "name": "Imeleke"}] },
        { "name": "Odogbolu", "id" :17, "communities": [{ "name": "Ijebu-Ode"},{ "name": "Awa"},{ "name": "Ikenne"},{ "name": "Owu"},{ "name": "Itele"}] },
        { "name": "Ogun Waterside", "id" :18, "communities": [{ "name": "Abigi"},{ "name": "Ipokia"},{ "name": "Makun"},{ "name": "Iwopin"},{ "name": "Ebute"}] },
        { "name": "Remo North", "id" :19, "communities": [{ "name": "Isara"},{ "name": "Sagamu"},{ "name": "Iperu"},{ "name": "Ilara"},{ "name": "Irolu"}] },
        { "name": "Shagamu", "id" :20, "communities": [{ "name": "Shagamu"},{ "name": "Ikorodu"},{ "name": "Epe"},{ "name": "Agbowa"},{ "name": "Ijebu-Ode"}] }] }
    }
]

export const states = (index?: string) => {
    if (index?.length) return state(index)
    return NigeriaStates.map((data) => ({ text: data.state.name, props: { value: data.state.name }}))
}

export const state = (index: string) => {
    const state = textCapitalize(index)
    const filter = NigeriaStates.filter((data) => data.state.name.includes(state));
    if(!filter.length) return [];

    return [{ text: filter[0].state.name, props: { value: filter[0].state.name }}]
};

export const local = (state: string, lga: string) => {
    const states = NigeriaStates.filter((data) => data.state.name === state);
    if(!states.length) return [];

    const locals = states[0].state.locals.filter((data) => data.name === lga);
    if(!locals.length) return [];

    return [{ text: locals[0].name, props: { value: locals[0].name }}]
};

export const locals = (state: string, lga?: string)  => {
    if (lga?.length) return local(state, lga)

    const states = NigeriaStates.filter((data) => data.state.name === state);
    if(!states.length) return [];

    return (states[0].state.locals).map((data) => ({ text: data.name, props: { value: data.name }}));
}

export const communities = (state: string, lga: string) => {
    const states = NigeriaStates.filter((data) => data.state.name === state);
    if(!states.length) return [];

    const localGov = states[0].state.locals.filter((data) => data.name === lga);
    if(!localGov.length) return [];

    return (localGov[0].communities).map((data) => ({ text: data.name, props: { value: data.name }}));
}