import { Button, useToast } from "@chakra-ui/react";
import { PrimaryInput, PrimarySelect, PrimaryTextarea, TargetGroupSelect, TrainingNameSelect } from "components";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { FilePicker } from 'react-file-picker';
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useProject } from "store/projects";
import { useAddTrainingMutation, useGetTrainingsQuery } from "store/training";
import { communities, locals, resolveApiError, states } from "utilities";
import { AddTrainingScheme } from "validations";
import { ContentBodyContainer, DashboardCardContainer } from "../../home";
import { useAddUploadMutation } from "store/uploads";

export const TrainingForm = () => {
    const navigate = useNavigate();
    const projectId: number = useProject().project?.id;
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const { isLoading } = useGetTrainingsQuery({
        
        query: "",
        project_id: projectId,
    });
    const toast = useToast({ position: "top-right" });
    const [requestUpload, { isLoading: isUploading, error }] = useAddUploadMutation();
    const [request] = useAddTrainingMutation();
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
        touched,
    } = useFormik({
        initialValues: {
            project_id: projectId,
            state: "",
            lga: "",
            community: "",
            name: "",
            target_group: "",
            age_group_male_1: "",
            age_group_female_1: "",
            age_group_male_2: "",
            age_group_female_2: "",
            age_group_male_3: "",
            age_group_female_3: "",
            age_group_male_4: "",
            age_group_female_4: "",
            duration: "",
            dates: "",
            attendance_upload_id: "",
            no_male_farmers: "",
            no_female_farmers: "",
            outcomes: "",
            lessons: "",
            challenges: "",
        },
        validationSchema: AddTrainingScheme(),
        onSubmit: () => initRequest(),
    });
    const initRequest = () => {
        const payload: any = { ...values };
        const uploads = new FormData();
        uploads.append('uploads[]', values.attendance_upload_id)

        requestUpload(uploads).unwrap().then((res) => {
            payload['attendance_upload_id'] = res.data[0].id
        request(payload)
            .unwrap()
            .then((res) => {
                console.log(payload);
                toast({
                    title: "Form Added, Your Manager Has Been Notified",
                    description: res?.response,
                    status: "success",
                });
                resetForm({}); // reset form
            })
            .catch((error) => {
                // console.log(error);
                toast({
                    title: "Request Failed",
                    description: resolveApiError(error),
                    status: "error",
                });
            });
        });
    };


    return (
        <ContentBodyContainer
            title="Training Summary Register"
            routesRule={"createTraining"}
        >
            <DashboardCardContainer title="Fill This Form Accurately" bodyClassName="p-3" >
                <div className="row g-2" >
                    <div className="col-lg-4 col-md-12">
                        <div className="col-auto mb-4">
                            <PrimarySelect
                                name="state"
                                placeholder="Select"
                                label="Select State"
                                options={states()}
                                onChange={handleChange}
                                size={"md"}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        {(values.state.length ?
                            <div className="col-auto mb-4">
                                <PrimarySelect
                                    name="lga"
                                    placeholder="Select Local Gov"
                                    options={locals(values.state)}
                                    onChange={handleChange}
                                    size={"md"}
                                    isDisabled={isLoading}
                                    style={{
                                        backgroundColor: "#F2FAFC",
                                        borderRadius: 0,
                                        borderColor: "#CAECF3",
                                    }}
                                />
                            </div> : <></>)}

                        {(values.state.length && values.lga.length ?
                            <div className="col-auto mb-4">
                                <PrimarySelect
                                    name="community"
                                    placeholder="Select Community"
                                    options={communities(values.state, values.lga)}
                                    onChange={handleChange}
                                    size={"md"}
                                    isDisabled={isLoading}
                                    style={{
                                        backgroundColor: "#F2FAFC",
                                        borderRadius: 0,
                                        borderColor: "#CAECF3",
                                    }}
                                />
                            </div> : <></>)}
                        <div className="col-auto mb-4">
                            <TrainingNameSelect
                                isRequired
                                name="name"
                                value={values.name}
                                error={Boolean(
                                    touched.name &&
                                    errors.name
                                )}

                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>

                        <div className="col-auto mb-4">
                            <TargetGroupSelect
                                isRequired
                                name="target_group"
                                value={values.target_group}
                                error={Boolean(
                                    touched.target_group &&
                                    errors.target_group
                                )}

                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="duration"
                                label="Training Duration (Days)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.duration}
                                error={Boolean(touched.duration && errors.duration)}
                                bottomText={errors.duration}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="dates"
                                label="Date"
                                placeholder="Date. "
                                type={"date"}
                                value={values.dates}
                                error={Boolean(touched.dates && errors.dates)}
                                bottomText={errors.dates}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                max={moment().format('YYYY-MM-DD')}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="no_male_farmers"
                                label="Number of male participants trained"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.no_male_farmers}
                                error={Boolean(touched.no_male_farmers && errors.no_male_farmers)}
                                bottomText={errors.no_male_farmers}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="no_female_farmers"
                                label="Number of female participants trained"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.no_female_farmers}
                                error={Boolean(touched.no_female_farmers && errors.no_female_farmers)}
                                bottomText={errors.no_female_farmers}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="age_group_male_1"
                                label="No of male youth trained (15 - 17 Yrs)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.age_group_male_1}
                                error={Boolean(touched.age_group_male_1 && errors.age_group_male_1)}
                                bottomText={errors.age_group_male_1}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="age_group_female_1"
                                label="No of female youth trained (15 - 17 Yrs)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.age_group_female_1}
                                error={Boolean(touched.age_group_female_1 && errors.age_group_female_1)}
                                bottomText={errors.age_group_female_1}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="age_group_male_2"
                                label="No of male youth trained (18 - 24 Yrs)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.age_group_male_2}
                                error={Boolean(touched.age_group_male_2 && errors.age_group_male_2)}
                                bottomText={errors.age_group_male_2}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="age_group_female_2"
                                label="No of female youth trained (18 - 24 Yrs)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.age_group_female_2}
                                error={Boolean(touched.age_group_female_2 && errors.age_group_female_2)}
                                bottomText={errors.age_group_female_2}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="age_group_male_3"
                                label="No of male youth trained (25 - 35 Yrs)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.age_group_male_3}
                                error={Boolean(touched.age_group_male_3 && errors.age_group_male_3)}
                                bottomText={errors.age_group_male_3}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="age_group_female_3"
                                label="No of female youth trained (25 - 35 Yrs)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.age_group_female_3}
                                error={Boolean(touched.age_group_female_3 && errors.age_group_female_3)}
                                bottomText={errors.age_group_female_3}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="age_group_male_4"
                                label="No of male youth trained (Above 35 Yrs)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.age_group_male_4}
                                error={Boolean(touched.age_group_male_4 && errors.age_group_male_4)}
                                bottomText={errors.age_group_male_4}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryInput
                                isRequired
                                name="age_group_female_4"
                                label="No of female youth trained (Above 35 Yrs)"
                                type="number"
                                placeholder="Your answer here..."
                                value={values.age_group_female_4}
                                error={Boolean(touched.age_group_female_4 && errors.age_group_female_4)}
                                bottomText={errors.age_group_female_4}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#F2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryTextarea
                                isRequired
                                name="outcomes"
                                label="Outcomes"
                                placeholder="Your answer here..."
                                value={values.outcomes}
                                error={Boolean(touched.outcomes && errors.outcomes)}
                                bottomText={errors.outcomes}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#g2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryTextarea
                                isRequired
                                name="lessons"
                                label="What were the lessons learned?"
                                placeholder="Your answer here..."
                                value={values.lessons}
                                error={Boolean(touched.lessons && errors.lessons)}
                                bottomText={errors.lessons}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#g2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <PrimaryTextarea
                                isRequired
                                name="challenges"
                                label="What challenges did you face?"
                                placeholder="Your answer here..."
                                value={values.challenges}
                                error={Boolean(touched.challenges && errors.challenges)}
                                bottomText={errors.challenges}
                                onChange={handleChange}
                                isDisabled={isLoading}
                                style={{
                                    backgroundColor: "#g2FAFC",
                                    borderRadius: 0,
                                    borderColor: "#CAECF3",
                                }}
                            />
                        </div>
                        <div className="col-auto mb-4">
                            <FilePicker
                                extensions={['docx', 'xlsx', 'csv']}
                                onChange={(attendance_upload_id: any) => {
                                    setFieldValue('attendance_upload_id', attendance_upload_id);
                                    setIsFileUploaded(true); 
                                }}
                            >
                                <button
                                    className="py-5 w-100"
                                    style={{ border: "1px dashed #7AD0E2" }}
                                >
                                    {isFileUploaded
                                        ? "File Uploaded Successfully"
                                        : "Upload Attendance (Formats: docx, xlsx & csv)"
                                    }
                                </button>
                            </FilePicker>

                        </div>
                        <div className="col-auto text-center mb-4">
                            <Button
                                colorScheme="teal"
                                onClick={() => initRequest()}
                                className={"fw-light"}
                                leftIcon={<MdOutlineAddCircleOutline size={13} />}
                                fontSize={"sm"}
                                backgroundColor={"#2A4153"}
                                color={"#ffffff"}
                                borderRadius={0}
                                padding={"16px, 48px, 16px, 48px"}
                            >
                                Submit Training Report
                            </Button>
                        </div>
                    </div>


                </div>
            </DashboardCardContainer>
        </ContentBodyContainer>
    );
};