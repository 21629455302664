import { ContentBodyContainer } from "../../home";
import { useEmailColumn } from "./components/emailColumn";
import { ThemeTable } from "components";
import { FaEye, FaTrash, FaPaperPlane } from "react-icons/fa";
import { useState } from "react";
import { resolveApiError } from "utilities";
import { useNavigate } from "react-router-dom";
import { useProject } from "store/projects";
import { Button, useToast } from "@chakra-ui/react";
import { useEmailsQuery, useDeleteMutation, Email } from "store/email";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from 'store/auth/hooks';

export const EmailsScreen = () => {
    const navigate = useNavigate();
    const columns = useEmailColumn();
    const userRole = useAuth().user?.account_type ?? "";
    const projectId: number = useProject().getProject()?.id;
    const { data, isLoading, refetch } = useEmailsQuery({ project_id: projectId, admin: userRole == "admin" ? 1 : 0 });
    const toast = useToast({ position: "top-right" });
    const [showModal, setShowModal] = useState(false);
    const [selectedEmailId, setSelectedEmailId] = useState(0);
    const [deleteEmail] = useDeleteMutation();
    const email = data?.data?.data.map((data: any) => {
      return Object.assign({}, {
        sender: `${data.user_info.fname} ${data.user_info.lname}`,
        title: data.title,
        message: data.message,
        date: data.created_at,
        id: data.id,
      })
    })

    const handleDelete = (row: any) => {
        setSelectedEmailId(row.original.id);
        setShowModal(true);
    };
    
    const handleConfirmDelete = () => {
        initDelete(selectedEmailId);
        setShowModal(false);
    };
    
    const handleCancelDelete = () => {
        setShowModal(false);
    };
    
    const initDelete = (mail: number) => {
        let payload = { project_id: projectId, mails: [mail]}
        deleteEmail(payload).unwrap().then((response) => {
          let msg = "Event has been deleted successfully"
          toast({ title: "Event", description: msg, status: "success" })
          refetch();
        }).catch((error) => {
          let msg = resolveApiError(error?.data?.response)
          toast({ title: "Request Failed", description: msg, status: "error"})
        });
    }

    return (
        <ContentBodyContainer  
          title="Communication"
          routesRule={"Communication"}
          rightCardHeaderComponent={
            userRole !== "admin" ?
            <div className="row g-3 mb-0 align-items-center">
              <div className="col-auto">
                <Button
                  colorScheme="teal"
                  onClick={() => navigate("/emails/send")}
                  leftIcon={
                    <FaPaperPlane className="svg-dark" size={12} />
                  }
                  className={"fw-bold"}
                  fontSize={"sm"}
                  backgroundColor={"#7AD0E2"}
                  color={"#000000"}
                  borderRadius={0}
                  padding={"12px, 20px, 12px, 20px"}
                  _hover={{ bg: "#bbc7ca" }}
                  transition={"background-color 0.5s ease-in-out"}
                >
                  Send Email
                </Button>
              </div>
            </div>
            : <></>
          }>
            <div className="col-xl-12">
                <ThemeTable
                    data={email ?? []}
                    columns={columns as any}
                    isLoading={isLoading}
                    onRefetch={refetch}
                    filename="Emails data"
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                        <div className="d-flex justify-content-evenly">
                        <div className="touchable pe-2">
                            <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="view-tooltip">View</Tooltip>}
                            >
                            <Button onClick={() => navigate(`/emails/view/` + (row.original as Email).id)}>
                                <FaEye size={16} color="#7F8C9F" />
                            </Button>
                            </OverlayTrigger>
                        </div>
                        <div className="touchable" onClick={() => handleDelete(row)}>
                            <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                            >
                            <div>
                                <FaTrash size={16} color="red" />
                            </div>
                            </OverlayTrigger>
                        </div>
                        </div>
                    )}
                />
            </div>

            <Modal show={showModal} onHide={handleCancelDelete}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Mail</Modal.Title>
              </Modal.Header>
              <Modal.Body className="fs-5 p-4">Are you sure you want to delete this mail?</Modal.Body>
              <Modal.Footer style={{ borderTop: "none" }}>
                <Button
                  variant="secondary"
                  onClick={handleCancelDelete}
                  style={{ backgroundColor: "#cccccc", color: "#ffffff" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={handleConfirmDelete}
                  style={{ backgroundColor: "red", color: "#ffffff" }}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
        </ContentBodyContainer>
    );
};
