import { PrimaryLoader } from "components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "store/auth";
import { BaseProject, useGetProjectsQuery, useProject } from "store/projects";
import vectorLogo from "../../../assets/images/Vectorlogo .png";
import "./projectStyles.css";

export const ProjectScreen = () => {
    const { setProject } = useProject()
    const { user } = useAuth()
    const { data: projects, isLoading } = useGetProjectsQuery({  query: "" });
    const navigate = useNavigate();
    const redirect = (project: BaseProject) => {
        setProject(project)
        navigate("/"); // navigate
    }

    return (
        <section className="bg george">
            <nav className="nav flex">
                <div className="logo">
                    <img src={vectorLogo} alt="heifer logo" />
                </div>
                <div className="profile-area">
                    <ul role="list" className="flex">
                        <li className="flex">
                            <div className="avatar mx-2">
                            {user && (
                                <span className="initials">{user.user_info.fname.charAt(0)}</span>
                            )}
                            </div>
                            {user?.user_info.fname}
                        </li>
                    </ul>
                </div>
            </nav>
            <div className="text flex">
                <p className="main-header">HEIFER NAIJA UNLOCK! <br /> Welcome {user?.user_info.fname}</p>
                <p className="sub-header">What would you like to work on today?</p>
            </div>
            <div className="card-holder flex">
                {(!projects?.data || isLoading) ?
                    <PrimaryLoader height={"70vh"} />
                    : projects?.data?.data.map((data) => (
                        <a onClick={() => redirect(data)}>
                            <div className="carddy flex maxwidth animate">
                                <img src={data.image.url} alt={data.name} />
                                <p className="card-text">{data.name}</p>
                            </div>
                        </a>
                    ))}
            </div>
        </section>
    );
}







