import { Button, ButtonProps, useToast } from "@chakra-ui/react";
import { CreateStoryScheme } from "validations";
import {
  ChakraAlertDialog,
  ChakraAlertDialogProps,
  PrimaryInput,
  PrimaryTextarea,
} from "components";
import { useFormik } from "formik";
import { resolveApiError } from "utilities";
import { useState } from "react";
import { ChakraProviderLoader } from "providers";
import { useCreateMutation } from "store/stories";
import { useProject } from "store/projects";
import { FilePicker } from 'react-file-picker'
import { useAddUploadMutation } from "store/uploads";

export interface CreateStoryDialogProps extends ChakraAlertDialogProps {
  useButton?: boolean;
  children?: string | React.ReactElement;
  buttonProps?: ButtonProps;
}

export const CreateStoryDialog: React.FC<CreateStoryDialogProps> = ({
  useButton = false,
  children,
  buttonProps,
  onClose = () => { },
  ...rest
}) => {
  const { project } = useProject();
  const [show, setShow] = useState(false);
  const toast = useToast({ position: "top-right" });
  const [request, { isLoading }] = useCreateMutation();
  const [requestUpload, { isLoading: isUploading, error }] = useAddUploadMutation();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
      file: "",
      project_id: project.id
    },
    validationSchema: CreateStoryScheme(),
    onSubmit: () => initRequest(),
  });

  const initRequest = () => {
    const uploads = new FormData();
    uploads.append('uploads[]', values.file)

    requestUpload(uploads).unwrap().then((res) => {
      const payload: any = { ...values }
      payload['uploads'] = [res.data[0].id]

      request(payload).unwrap().then((res) => {
        toast({ title: "Story", description: res?.response, status: "success" });
        initOnClose(); resetForm({}); // reset form 
      }).catch((error) => {
        toast({ title: "Request Failed", description: resolveApiError(error), status: "error" });
      });
    }).catch((error) => {
      toast({ title: "Request Failed", description: resolveApiError(error), status: "error" })
    })
  }

  const initOnClose = () => { setShow(false); onClose(); };

  return (
    <ChakraProviderLoader>
      {useButton && (
        <Button
          onClick={() => setShow(true)}
          {...buttonProps}
          _hover={{
            bg: "#bbc7ca",
            transition: "background-color 0.5s ease-in-out",
          }}
        >
          {children}
        </Button>
      )}
      <ChakraAlertDialog
        title={"Add Project Story"}
        size={"xl"}
        proceedButtonProps={{ colorScheme: "teal" }}
        proceedButtonDefaultChild={"Upload"}
        isOpen={rest?.isOpen ? rest?.isOpen : show}
        onProceed={initRequest}
        onClose={initOnClose}
        isProceeding={isLoading}
        {...rest}
      >
        <div className="row g-4">
          <div className="col-12">
            <PrimaryInput
              isRequired
              name="title"
              label="Story title"
              placeholder="Enter story title"
              value={values.title}
              error={Boolean(touched.title && errors.title)}
              bottomText={errors.title}
              onChange={handleChange}
              isDisabled={isLoading}
              style={{
                backgroundColor: "#F2FAFC",
                borderRadius: 0,
                borderColor: "#CAECF3",
              }}
            />
          </div>

          <div className="col-12">
            <PrimaryTextarea
              isRequired
              name="description"
              label={"Description"}
              placeholder="Enter quick description of the story...."
              size={"lg"}
              rows={7}
              value={values.description}
              error={Boolean(touched.description && errors.description)}
              bottomText={errors.description}
              onChange={handleChange}
              isDisabled={isLoading}
              style={{
                backgroundColor: "#F2FAFC",
                borderRadius: 0,
                borderColor: "#CAECF3",
              }}
            />
          </div>

          <div className="col-12">
            <FilePicker
              extensions={['jpeg', 'png', 'jpg']}
              onChange={(file: any) => {
                setFieldValue('file', file);
                setIsFileUploaded(true);
              }}
            >
              <button
                className="py-5 w-100"
                style={{ border: "1px dashed #7AD0E2" }}
              >
                {isFileUploaded
                  ? "Image Uploaded Successfully"
                  : "Upload image (Format supported: jpeg, png & jpg)"
                }
              </button>
            </FilePicker>
          </div>
        </div>
      </ChakraAlertDialog>
    </ChakraProviderLoader>
  );
};
