import { PrimarySelect, PrimarySelectProp } from 'components';

interface PrimaryFunctionSelectProps extends PrimarySelectProp {

}
export const PrimaryFunctionSelect: React.FC<PrimaryFunctionSelectProps> = ({
    ...rest
}) => {
    return (
        <PrimarySelect
            {...rest}
        >
            <option value="Producing">Producing</option>
            <option value="Transportation">Transportation</option>
            <option value="Savings/Lending">Savings/Lending</option>
            <option value="Financing">Financing</option>
            <option value="Savings/Lending">Savings/Lending</option>
            <option value="Buying and selling">Buying and selling</option>
            <option value="Marketing">Marketing</option>
            <option value="Input Supplier">Input Supplier</option>
            <option value="Processing">Processing</option>
            <option value="Offaker">Offaker</option>
            <option value="Retailing">Retailing</option>
            <option value="Packaging">Packaging</option>
            <option value="Training Provides">Training Provides</option>
            <option value="Other Services">Other Services</option>
        </PrimarySelect>
    )
}
