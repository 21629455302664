import { ShgInfo, Shg } from 'store/shg';
import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const useAllShgsColumn = () => {
    return useMemo<MRT_ColumnDef<ShgInfo>[]>(
      () => [
        {
          header: "Record Name",
          accessorFn: (row) => `${row.entity_name}`,
        },
        {
          header: "Business Plan (Yes/No)",
          accessorFn: (row) => `${row.business_plan}`,
        },
        {
          header: "Financials (Yes/No)",
          accessorFn: (row) => `${row.financial}`,
        },
        {
          header: "Service Provider",
          accessorFn: (row) => `${row.service_provider}`,
        },
        {
          header: "Total Sales",
          accessorFn: (row) => `${row.value}`,
        },
        {
          header: "Meeting",
          accessorFn: (row) => `${row.hold_meeting}`,
        },
        {
          header: "Cash",
          accessorFn: (row) => `${row.cash}`,
        },
        {
          header: "Assets",
          accessorFn: (row) => `${row.asset}`,
        },
        {
          header: "Quantity Accessed",
          accessorFn: (row) => `${row. how_much_was_accessed}`,
        },
        {
          header: "Conduct Sales",
          accessorFn: (row) => `${row.conduct_sales}`,
        },
        {
          header: "Commodity Sold",
          accessorFn: (row) => `${row.commodity_sold}`,
        },
        {
          header: "Quantity Sold",
          accessorFn: (row) => `${row.quantity_sold}`,
        },
        {
          header: "Measurement Unit",
          accessorFn: (row) => `${row.measurement_unit}`,
        },
        {
          header: "Engaged Entities",
          accessorFn: (row) => `${row.entities_engaged}`,
        },
        {
          header: "Entity Details",
          accessorFn: (row) => `${row.entity_details}`,
        },
        {
          header: "Primary Function",
          accessorFn: (row) => `${row.primary_function}`,
        },
        {
          header: "Secondary Function",
          accessorFn: (row) => `${row.secondary_function}`,
        },
        {
          header: "State",
          accessorFn: (row) => `${row.state}`,
        },
        {
          header: "LGA",
          accessorFn: (row) => `${row.lga}`,
        },
        {
          header: "Community",
          accessorFn: (row) => `${row.community}`,
        },
        {
          header: "Price",
          accessorFn: (row) => `${row.price}`,
        },
        {
          header: "Comment",
          accessorFn: (row) => `${row.comment}`,
        },
        {
          header: "Date Added",
          accessorFn: (row) => `${moment(row.created_at).format('MMMM Do, YYYY')}`,
        },
      ],
      []
    );
};