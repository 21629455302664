import { object, string, number} from "yup";

export const AddTrainingScheme = () => {
    return object({
        training_name: string().required('Training Name is required'),
        target_group: string().required('Target Group is required'),
        dates: string().required('Target Date is required'),
        youth_age_group_one_male: number().required('Total Male Youth is required'),
        youth_age_group_one_female: number().required('Total Female Youth  is required'),
        youth_age_group_two_male: number().required('Total Male Youth is required'),
        youth_age_group_two_female: number().required('Total Female Youth is required'),
        youth_age_group_three_male: number().required('Total Male Youth is required'),
        youth_age_group_three_female: number().required('Total Female Youth  is required'),
        youth_age_group_four_male: number().required('Total Male Youth is required'),
        youth_age_group_four_female: number().required('Total Female Youth  is required'),
        training_duration: string().required('Training Duration is required'),
        no_male_farmers: number().required('Total Male Participants is required'),
        no_female_farmers: number().required('Total Female Participants is required'),
        outcomes: string().required('This is required'),
        lessons: string().required('This is required'),
        challenges: string().required('This is required'),
    });
}

export const EditTrainingScheme = () => {
    return object({
        training_name: string().required('Training Name is required'),
        target_group: string().required('Target Group is required'),
        dates: string().required('Target Date is required'),
        youth_age_group_one_male: number().required('Total Male Youth is required'),
        youth_age_group_one_female: number().required('Total Female Youth  is required'),
        youth_age_group_two_male: number().required('Total Male Youth is required'),
        youth_age_group_two_female: number().required('Total Female Youth is required'),
        youth_age_group_three_male: number().required('Total Male Youth is required'),
        youth_age_group_three_female: number().required('Total Female Youth  is required'),
        youth_age_group_four_male: number().required('Total Male Youth is required'),
        youth_age_group_four_female: number().required('Total Female Youth  is required'),
        training_duration: string().required('This is required'),
        no_male_farmers: number().required('Total Male Participants is required'),
        no_female_farmers: number().required('Total Female Participants is required'),
        outcomes: string().required('This is required'),
        lessons: string().required('This is required'),
        challenges: string().required('This is required'),
    });
}
