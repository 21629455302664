import { BiCalendar } from "react-icons/bi";
import {
  BsFillCameraFill,
  BsFillClipboardDataFill,
  BsFillPatchCheckFill,
  BsGridFill,
} from "react-icons/bs";
import {
  FaUser, FaChartBar
} from "react-icons/fa";
import { GiFarmer } from "react-icons/gi";
import { HiDocument, HiMail } from "react-icons/hi";
import { MdMyLocation, MdNoteAdd } from "react-icons/md";
import { RiHomeFill } from "react-icons/ri";
import { GiArchiveRegister } from "react-icons/gi";
import { LinkItemProps } from "../interface";
import { useAuth } from 'store/auth/hooks';

export const AdminMenuLinkItem = () => {
  const { user } = useAuth();
  const userRole = user?.account_type ?? ""
  let exceptRoles = "state_coordinator community_facilitator"

  let menuLinksItems: Array<LinkItemProps> = [
    { name: "Dashboard", icon: RiHomeFill, path: "/" },
    { name: "ArcGIS Online", icon: MdMyLocation, path: "maps" },
    // { name: "SurveyCTO", icon: MdNoteAdd, path: "forms" },
    { name: "Households", icon: GiFarmer, path: "farmers" },
    { name: "Groups", icon: BsGridFill, path: "groups" },
    { name: "Interventions", icon: BsFillPatchCheckFill, path: "interventions" },
    { name: "Record Tracking", icon: BsFillClipboardDataFill, path: exceptRoles.includes(userRole) ? "records/sub" :  "records" },
    { name: "Training Register", icon: GiArchiveRegister, path: exceptRoles.includes(userRole) ? "trainings/form" :  "trainings" },
    { name: "Monthly Reporting Register", icon: HiDocument, path: exceptRoles.includes(userRole) ? "reports/form" : "reports" },
    { name: "Indicators Monitoring", icon: FaChartBar, path: exceptRoles.includes(userRole) ? "indicators" :  "indicators" },
    // { name: "Success Stories", icon: BsFillCameraFill, path: "stories" },
    // { name: "Schedule", icon: BiCalendar, path: "schedule" },
    // { name: "Communication", icon: HiMail, path: "emails" },
    { name: "Users", icon: FaUser, path: "users" },
  ];

  let exceptLinks = "ArcGIS Online Users SurveyCTO Indicators Monitoring"
  if (userRole === "project_manager") menuLinksItems = menuLinksItems.filter((e) => !"Users".includes(e.name))
  if (exceptRoles.includes(userRole)) menuLinksItems = menuLinksItems.filter((e) => !exceptLinks.includes(e.name))

  return menuLinksItems
}