import { IndicatorInfo } from 'store/indicators';
import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ProgressBar from 'react-bootstrap/ProgressBar';

export const useAllIndicatorsColumn = () => {
  return useMemo<MRT_ColumnDef<IndicatorInfo>[]>(() => [
    {
      header: 'Indicator Name',
      accessorFn: (row) => row.name,
      Cell: ({ row }) => _.startCase(row.original.name),
    },
    {
      header: 'Annual Target',
      accessorFn: (row) => row.annual_target,
    },
    {
      header: 'Achieved',
      accessorFn: (row) => row.actual_target,
    },
    {
      header: 'Date Added',
      accessorFn: (row) => moment(row.created_at).format('MMMM Do, YYYY').toString(),
    },
    {
      header: 'Progress',
      Cell: ({ row }) => {
        const actualTarget = row.original.actual_target;
        const annualTarget = row.original.annual_target;
        const progress = Math.ceil((actualTarget / annualTarget) * 100) || 0;
        return <ProgressBar now={progress} label={`${progress}%`} className="custom-progress-bar" />;
      },
    },
  ], []);
};
