import { object, string } from "yup";

export const CreateEventScheme = () => {
    return object({
        title: string().required('Title is required'),
        description: string().required('Description is required'),
        starts_at: string().required('Start at is required'),
        ends_at: string().required('End at is required'),
        location: string().required('Location is required'),
    });
}
