import { ContentBodyContainer } from "../../home";
import { FaStore } from "react-icons/fa";
import { BsFillPersonCheckFill, BsFillPatchCheckFill } from "react-icons/bs";
import { MdPersonAddAlt1 } from "react-icons/md";
import { currencyFormatter } from "utilities";
import { useGetDashboardStatisticsQuery } from "store/dashboard";
import { useProject } from "store/projects";


export const DashboardScreenCF = () => {

  const projectId: number = useProject().project.id;
  const { data } = useGetDashboardStatisticsQuery({ project_id: projectId });


  return (
    <ContentBodyContainer title="">
      {/*end col*/}

      <div className="col-xl-3 col-md-6">
        {/* card */}
        <div className="card card-animate">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="avatar-sm flex-shrink-0 mt-5">
                <div
                  style={{
                    backgroundColor: "#0BB508",
                    borderRadius: "50%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <BsFillPersonCheckFill size={24} className="svg-light" />
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden text-end">
                <p className="fs-5 fw-medium text-dark text-truncate mb-3">
                  {" "}
                  Registered Households
                </p>
                <h4 className="fs-1 fw-bold text-dark mb-5">{currencyFormatter(data?.data.no_households ?? 0)}</h4>
              </div>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
      </div>

      <div className="col-xl-3 col-md-6">
        {/* card */}
        <div className="card card-animate">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="avatar-sm flex-shrink-0 mt-5">
                <div
                  style={{
                    backgroundColor: "#48A0FF",
                    borderRadius: "50%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <FaStore size={24} className="svg-light" />
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden text-end">
                <p className="fs-5 fw-medium text-dark text-truncate mb-3">
                  {" "}
                  Communities Covered
                </p>
                <h4 className="fs-1 fw-bold text-dark mb-5">{currencyFormatter(data?.data.no_community ?? 0)}</h4>
              </div>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
      </div>

      <div className="col-xl-3 col-md-6">
        {/* card */}
        <div className="card card-animate">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="avatar-sm flex-shrink-0 mt-5">
                <div
                  style={{
                    backgroundColor: "#FFD914",
                    borderRadius: "50%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <MdPersonAddAlt1 size={24} className="svg-light" />
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden text-end">
                <p className="fs-5 fw-medium text-dark text-truncate mb-3">
                  {" "}
                  No. of SH Groups
                </p>
                <h4 className="fs-1 fw-bold text-dark mb-5">{currencyFormatter(data?.data.no_group ?? 0)}</h4>
              </div>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
      </div>

      <div className="col-xl-3 col-md-6">
        {/* card */}
        <div className="card card-animate">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="avatar-sm flex-shrink-0 mt-5">
                <div
                  style={{
                    backgroundColor: "#B00074",
                    borderRadius: "50%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <BsFillPatchCheckFill size={24} className="svg-light" />
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden text-end">
                <p className="fs-5 fw-medium text-dark text-truncate mb-3">
                  {" "}
                  Intervention Administered
                </p>
                <h4 className="fs-1 fw-bold text-dark mb-5">{currencyFormatter(data?.data.no_interventions ?? 0)}</h4>
              </div>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
      </div>

      <div className="col-xl-12 col-md-12 mb-4">
        <div
          className="animate"
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <iframe
            title="Indicators"
            src="https://app.powerbi.com/view?r=eyJrIjoiODEwNzZkZjktMmIyOS00NzE2LWFkYTAtMWQ0OWYwN2E1Y2RmIiwidCI6ImRhNmE2OWUzLTNiY2QtNGY0NC1hMzQwLWFlYWQwY2ZkYTA4ZiIsImMiOjN9"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: "none",
              fontFamily: "Montserrat, sans-serif !important",
            }}
          ></iframe>
        </div>
      </div>

      <div className="col-xl-12 col-md-12 mb-4">
        <div
          className="animate"
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <iframe
            title="Dashboard_1"
            src="https://app.powerbi.com/view?r=eyJrIjoiOGRjMTRmYTYtNTZkNy00OWEzLWFlYzMtOTcxZjBlYjBhMGJjIiwidCI6ImRhNmE2OWUzLTNiY2QtNGY0NC1hMzQwLWFlYWQwY2ZkYTA4ZiIsImMiOjN9"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: "none",
              fontFamily: "Montserrat, sans-serif !important",
            }}
          ></iframe>
        </div>
      </div>

    </ContentBodyContainer>
  );
};
