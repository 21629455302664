// import { AuthBoxedContainer, DashboardBaseViewContainer } from 'containers';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomeScreen, LoginScreen } from 'screens';
import {
  AddGroup,
  DashboardScreen,
  DashboardScreenCF,
  DashboardScreenSC,
  FarmerScreen,
  GroupScreen,
  IndicatorsScreen,
  InterventionScreen,
  MapsScreen,
  ProjectScreen,
  RecordScreen,
  RegisterFarmers,
  ReportForm,
  ReportsScreen,
  ShgRecordForm,
  StoriesScreen,
  TrainingForm,
  TrainingScreen,
  UserScreen,
  ViewFarmers,
  ViewGroups,
  ViewInterventions,
  ViewProfile,
  ViewUsers,
  ViewStory,
  EventsScreen,
  EmailsScreen
} from "screens/heiferScreens";
import { SendEmail } from 'screens/heiferScreens/communication/sendEmail';
import { ViewEmail } from 'screens/heiferScreens/communication/viewEmail';
import { CreateEvent } from 'screens/heiferScreens/schedule/createEvent';
import { ViewEvent } from 'screens/heiferScreens/schedule/viewEvent';
import { ForgotScreen } from '../screens/auth/forgot';
import { PasswordScreen } from '../screens/auth/password';
import { useAuth } from 'store/auth/hooks';

export const RootNavigator = () => {

  const { user } = useAuth();
  const userRole = user?.account_type ?? ""
  let includeRoles = "admin project_manager"
  let dashboard = <DashboardScreen />
  if (userRole === "state_coordinator") dashboard = <DashboardScreenSC />
  if (userRole === "community_facilitator") dashboard = <DashboardScreenCF />
// Create a new component for the "Forms" screen with the external link
const FormsScreenWithExternalLink = () => {
  // Navigate to the external URL when the component is rendered
  window.location.href = 'https://heifer.surveycto.com/collect/';
  // Return null or any other desired component if you want to render something on the page
  return null;
};
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth">
          <Route index element={<LoginScreen />} />
          <Route path="login" element={<LoginScreen />} />
          <Route path="forgot" element={<ForgotScreen />} />
          <Route path="reset/:token" element={<PasswordScreen />} />
          <Route path="projects" element={<ProjectScreen />} />
        </Route>

        <Route path="/" element={<HomeScreen />}>
          <Route index element={dashboard} />
          <Route path="register" element={<RegisterFarmers />} />
          <Route path="groups/add" element={<AddGroup />} />
          <Route path="records/sub" element={<ShgRecordForm />} />
          {(userRole) === "admin" ? <Route path="users" element={<UserScreen />} /> : ""}
          {includeRoles.includes(userRole) ? <>
              <Route path="maps" element={<MapsScreen />} /> 
              <Route path="forms" element={<FormsScreenWithExternalLink />} />
              <Route path="records" element={<RecordScreen />} />
              <Route path="reports" element={<ReportsScreen />} />
              <Route path="trainings" element={<TrainingScreen />} />
              <Route path="indicators" element={<IndicatorsScreen />} />
            </> : ""}
          <Route path="groups" element={<GroupScreen />} />
          <Route path="farmers" element={<FarmerScreen />} />
          <Route path="farmers/view/:id" element={<ViewFarmers />} />
          <Route path="groups/view/:id" element={<ViewGroups />} />
          {(userRole) === "admin" ? <Route path="users/view/:id" element={<ViewUsers />} /> : ""}
          <Route path="profile/view" element={< ViewProfile />} />
          <Route path="interventions/view/:id" element={<ViewInterventions />} />
          <Route path="interventions" element={<InterventionScreen />} />
          <Route path="reports/form" element={<ReportForm />} />
          <Route path="trainings/form" element={<TrainingForm />} />
          <Route path="emails" element={<EmailsScreen/>} />
          {(userRole) !== "admin" ? <Route path="emails/send" element={<SendEmail />} /> : ""}
          <Route path="emails/view/:id" element={<ViewEmail />} />
          <Route path="stories" element={<StoriesScreen />} />
          <Route path="stories/view/:id" element={<ViewStory />} />
          <Route path="schedule" element={ <EventsScreen/> } />
          {(userRole) === "admin" ? <Route path="schedule/create" element={<CreateEvent />} /> : ""}
          {<Route path="schedule/view/:id" element={<ViewEvent />} /> }
        </Route>
      </Routes>
    </BrowserRouter>
  );
}