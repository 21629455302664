import { TrainingInfo, Training } from 'store/training';
import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UserInfo } from 'store/user';

export const useAllTrainingsColumn = () => {
    return useMemo<MRT_ColumnDef<TrainingInfo>[]>(
      () => [
        {
          header: "Uploaded By",
          accessorFn: (row) => `${row.lname}`,
          Cell: ({ row }) => (
            <Link
              to={"/users/view/" + (row.original as unknown as UserInfo).id}
              state={{ member: row.original }}
              className="text-decoration-underline"
            >
              {_.startCase(`${row.original.lname}`)} 
            </Link>
          ),
        },
       
        {
          header: "Training Name",
          accessorFn: (row) => `${row.name}`,
        },
        {
          header: "Target Group",
          accessorFn: (row) => `${row.target_group}`,
        },
        {
          header: "Duration (Days)",
          accessorFn: (row) => `${row.duration}`,
        },
        {
          header: "Male Farmers Trained",
          accessorFn: (row) => `${row.no_male_farmers}`,
        },
        {
          header: "Female Farmers Trained",
          accessorFn: (row) => `${row.no_female_farmers}`,
        },
        {
          header: "Date Added",
          accessorFn: (row) => `${moment(row.created_at).format('MMMM Do, YYYY')}`,
        }, 
        {
          header: "View Attendance File",
          accessorFn: (row) => (
            <Link
              to={row.attendance_upload.url ?? ''}
              target='_blank'
              className="text-decoration-underline"
            >
              {row.attendance_upload.url ? row.attendance_upload.url.substring(0, 55)+'...' : ''}
            </Link>
          )
      },
              
      ],
      []
    );
};