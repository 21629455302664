import { ContentBodyContainer } from "../../home";
import { CreateStoryDialog } from "./createStory";
import { MdCloudUpload } from "react-icons/md";
import { useStoriesQuery, Story, useDeleteMutation } from "store/stories";
import { useNavigate } from "react-router-dom";
import { useProject } from "store/projects";
import { Button, useToast } from "@chakra-ui/react";
import { useStoriesColumn } from "./components/storiesColumn";
import { ThemeTable } from "components";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaEye, FaTrash, FaDownload } from "react-icons/fa";
import { useState } from "react";
import { resolveApiError } from "utilities";

export const StoriesScreen = () => {
    const navigate = useNavigate();
    const columns = useStoriesColumn();
    const projectId: number = useProject().getProject()?.id;
    const { data, isLoading, refetch } = useStoriesQuery({  project_id: projectId });
    const toast = useToast({ position: "top-right" });
    const [showModal, setShowModal] = useState(false);
    const [selectedStoryId, setSelectedStoryId] = useState(0);
    const [deleteStory] = useDeleteMutation();
    const stories = data?.data?.data.map((data: any) => {
      const original = data.original || {}; 
      return Object.assign({}, {
        title: data.title,
        description: data.description,
        uploaded_by: `${data.creator.user_info.fname} ${data.creator.user_info.lname}`,
        link: data.uploads[0]?.url,
        id: data.id,
      })
    })

    const handleDelete = (row: any) => {
        setSelectedStoryId(row.original.id);
        setShowModal(true);
      };
    
      const handleConfirmDelete = () => {
        initDelete(selectedStoryId);
        setShowModal(false);
      };
    
      const handleCancelDelete = () => {
        setShowModal(false);
      };
    
      const initDelete = (record: number) => {
        let payload = { project_id: projectId, records: [record]}
        deleteStory(payload).unwrap().then((response) => {
          let msg = "Intervention has been deleted successfully"
          toast({ title: "Intervention Deleted", description: msg, status: "success" })
          refetch();
        }).catch((error) => {
          let msg = resolveApiError(error?.data?.response)
          toast({ title: "Request Failed", description: msg, status: "error"})
        });
      }

    return (
        <ContentBodyContainer 
            title="Stories" 
            routesRule={"Stories"}
            rightCardHeaderComponent={
                <div className="row g-3 mb-0 align-items-center">
                  <div className="col-auto">
                    <CreateStoryDialog
                      useButton={true}
                      buttonProps={{
                        leftIcon: (
                          <MdCloudUpload className="svg-dark" size={12} />
                        ),
                        fontSize: "sm",
                        className: "fw-bold",
                        backgroundColor: "#7AD0E2",
                        color: "#000000",
                        borderRadius: 0,
                        padding: "12px, 20px, 12px, 20px",
                      }}
                      onClose={refetch}
                    >
                      Upload Story
                    </CreateStoryDialog>
                  </div>
                </div>
              }
        >
            <div className="col-xl-12">
                <ThemeTable
                    data={stories ?? []}
                    columns={columns as any}
                    isLoading={isLoading}
                    onRefetch={refetch}
                    filename="Stories data"
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                        <div className="d-flex justify-content-evenly">
                        <div className="touchable pe-2">
                            <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="view-tooltip">View</Tooltip>}
                            >
                            <Button onClick={() => navigate(`/stories/view/` + (row.original as Story).id)}>
                                <FaEye size={16} color="#7F8C9F" />
                            </Button>
                            </OverlayTrigger>
                        </div>
                        <div className="touchable pe-2">
                            <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="edit-tooltip">Download</Tooltip>}
                            >
                            <a href={(row.original as Story).link} download target="_blank">
                                <FaDownload size={16} color="#7F8C9F" />
                            </a>
                            </OverlayTrigger>
                        </div>
                        <div className="touchable" onClick={() => handleDelete(row)}>
                            <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                            >
                            <div>
                                <FaTrash size={16} color="red" />
                            </div>
                            </OverlayTrigger>
                        </div>
                        </div>
                    )}
                />
            </div>

            <Modal show={showModal} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                <Modal.Title>Delete Story</Modal.Title>
                </Modal.Header>
                <Modal.Body className="fs-5 p-4">Are you sure you want to delete this story?</Modal.Body>
                <Modal.Footer style={{ borderTop: "none" }}>
                <Button
                    variant="secondary"
                    onClick={handleCancelDelete}
                    style={{ backgroundColor: "#cccccc", color: "#ffffff" }}
                >
                    Cancel
                </Button>
                <Button
                    variant="danger"
                    onClick={handleConfirmDelete}
                    style={{ backgroundColor: "red", color: "#ffffff" }}
                >
                    Delete
                </Button>
                </Modal.Footer>
            </Modal>
        </ContentBodyContainer>
    );
};
